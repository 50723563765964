import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  // RESET_PASSWORD,
  // RESET_PASSWORD_SUCCESS,
  // RESET_PASSWORD_ERROR,
  GET_DATA_FROM_TOKEN,
  CHANGE_YACHT,
  CHANGE_USER_IMAGE,
  CHANGE_USER_USERNAME,
  CHANGE_YACHT_DETAIL_BY_COMPANY_DETAIL,

  LOAD_NOTIFY,
  LOAD_NOTIFY_SUCCESS,
} from '../actions';
// import { getCurrentUser } from '../../helpers/Utils';
// import { isAuthGuardActive, currentUser } from '../../constants/defaultValues';
// let yachtIDValidate = null
// let roleManagementValidate = null
// let CurrentYachtValidate = null
// yacht_used
const INIT_STATE = {
  user: '',
  user_id: '',
  userImage: '',
  name: '',
  yacht_id: '',
  token: localStorage.getItem("token"),
  roleManagement: {
    roleDashboard: { view: false, add: false, delete: false },
    roleBooking: { view: false, add: false, delete: false },
    rolePromotion: { view: false, add: false, delete: false },
    roleMessenger: { view: false, add: false, delete: false },
    roleComment: { view: false, add: false, delete: false },
    roleFinancial: { view: false, add: false, delete: false },
    roleYacht: { view: false, add: false, delete: false },
    roleUser: { view: false, add: false, delete: false },
    roleCompanyDetail: { view: false, add: false, delete: false }
  },
  yacht: [],
  currentYacht: null,
  loading: false,
  forgotUserMail: "",
  exp: 0,
  alertErrorLogin: false,
  error: '',
  notify: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_USER_SUCCESS: {
      localStorage.setItem('yacht_used', action.payload.yacht_id)
      return {
        ...state,
        loading: false,
        user: action.payload.userName,
        name: action.payload.name,
        user_id: action.payload.user_id,
        userImage: action.payload.userImage,
        token: action.payload.accessToken,
        roleManagement: action.payload.roleManagement,
        yacht_id: action.payload.yacht_id,
        yacht: action.payload.yacht,
        currentYacht: action.payload.yacht.find(item => item.id_yacht === action.payload.yacht_id),
        exp: action.payload.expConfirm,
      };
    }
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload,
      };
    case GET_DATA_FROM_TOKEN: {
      const yachtUsed = localStorage.getItem("yacht_used")
      let yachtIDValidate = action.payload.yacht_id || state.yacht_id;
      let roleManagementValidate = action.payload.roleManagement || state.roleManagement
      let CurrentYachtValidate = action.payload.yacht.find(item => item.id_yacht === action.payload.yacht_id)
      if (yachtUsed) {
        const CurrentYachtValidateByYachtUsed = action.payload.yacht.find(item => item.id_yacht === yachtUsed)
        if (CurrentYachtValidateByYachtUsed.suspended === 0) {
          yachtIDValidate = CurrentYachtValidateByYachtUsed ? yachtUsed : action.payload.yacht_id
          CurrentYachtValidate = CurrentYachtValidateByYachtUsed || action.payload.yacht.find(item => item.id_yacht === action.payload.yacht_id)
          roleManagementValidate = CurrentYachtValidateByYachtUsed ? CurrentYachtValidateByYachtUsed.roleManagement : action.payload.roleManagement
        }
      }
      return {
        ...state,
        loading: false,
        user: action.payload.userName,
        name: action.payload.name,
        user_id: action.payload.user_id,
        userImage: action.payload.userImage,
        token: action.payload.accessToken,
        roleManagement: roleManagementValidate,
        yacht_id: yachtIDValidate,
        yacht: action.payload.yacht,
        currentYacht: CurrentYachtValidate,
        exp: action.payload.expConfirm,
      };
    }
    case CHANGE_YACHT:
      return {
        ...state,
        loading: false,
        roleManagement: action.payload.roleManagement,
        yacht_id: action.payload.yacht_id,
        currentYacht: action.payload.currentYacht,
      };
    case CHANGE_USER_IMAGE:
      return {
        ...state,
        loading: false,
        userImage: action.payload.userImage,
      };
    case CHANGE_USER_USERNAME:
      return {
        ...state,
        loading: false,
        name: action.payload.newUserName,
      };
    case CHANGE_YACHT_DETAIL_BY_COMPANY_DETAIL: {
      const oldCurrentYacht = state.currentYacht
      const newCurrentYacht = {
        ...oldCurrentYacht,
        logo_yacht_url: action.payload.newYachtLogo,
        name_yacht: action.payload.newYachtName,
        name_yacht_en: action.payload.newYachtNameEN,
      }
      return {
        ...state,
        loading: false,
        currentYacht: newCurrentYacht,
      };
    }
    case LOAD_NOTIFY:
      return { ...state, loading: true, error: '' };
    case LOAD_NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        notify: action.payload
      }
    // case RESET_PASSWORD:
    //   return { ...state, loading: true, error: '' };
    // case RESET_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     newPassword: action.payload,
    //     resetPasswordCode: '',
    //     error: '',
    //   };
    // case RESET_PASSWORD_ERROR:
    //   return {
    //     ...state,
    //     loading: false,
    //     newPassword: '',
    //     resetPasswordCode: '',
    //     error: action.payload.message,
    //   };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: '',
        user_id: '',
        name: '',
        userImage: '',
        yacht_id: '',
        yacht: [],
        token: null,
        roleManagement: {
          roleDashboard: { view: false, add: false, delete: false },
          roleBooking: { view: false, add: false, delete: false },
          rolePromotion: { view: false, add: false, delete: false },
          roleMessenger: { view: false, add: false, delete: false },
          roleComment: { view: false, add: false, delete: false },
          roleFinancial: { view: false, add: false, delete: false },
          roleYacht: { view: false, add: false, delete: false },
          roleUser: { view: false, add: false, delete: false },
          roleCompanyDetail: { view: false, add: false, delete: false }
        },
        loading: false,
        exp: 0,
        alertErrorLogin: false,
        currentYacht: null,
        error: '',
        notify: [],
      };
    default:
      return { ...state };
  }
};
