// eslint-disable-next-line import/no-cycle
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  // RESET_PASSWORD,
  // RESET_PASSWORD_SUCCESS,
  // RESET_PASSWORD_ERROR,
  GET_DATA_FROM_TOKEN,
  CHANGE_YACHT,
  CHANGE_USER_IMAGE,
  CHANGE_USER_USERNAME,
  CHANGE_YACHT_DETAIL_BY_COMPANY_DETAIL,

  LOAD_NOTIFY,
  LOAD_NOTIFY_SUCCESS,
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const dataDataFromToken = (data) => ({
  type: GET_DATA_FROM_TOKEN,
  payload: data
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

// export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
//   type: RESET_PASSWORD,
//   payload: { resetPasswordCode, newPassword, history },
// });
// export const resetPasswordSuccess = (newPassword) => ({
//   type: RESET_PASSWORD_SUCCESS,
//   payload: newPassword,
// });
// export const resetPasswordError = (message) => ({
//   type: RESET_PASSWORD_ERROR,
//   payload: { message },
// });
export const getNotify = (data, token) => ({
  type: LOAD_NOTIFY,
  payload: data,
  token
});
export const setNotify = (data) => ({
  type: LOAD_NOTIFY_SUCCESS,
  payload: data
});


export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user,
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});
export const changeYacht = (data) => ({
  type: CHANGE_YACHT,
  payload: data,
});
export const changeUserImage = (data) => ({
  type: CHANGE_USER_IMAGE,
  payload: data,
});
export const changeUserName = (data) => ({
  type: CHANGE_USER_USERNAME,
  payload: data,
});
export const changeYachtNameandImage = (data) => ({
  type: CHANGE_YACHT_DETAIL_BY_COMPANY_DETAIL,
  payload: data,
});
// changeYacht