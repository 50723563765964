import Provider from "./Provider-Api";
import CheckApi from "./check-api";


export const companyDetailGetYacht = async (data, token) => {
    CheckApi('getYacht', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/companyDetail/getYacht",
        data,
        token
    });
    CheckApi('dataRes-getYacht', dataRes)
    return dataRes;
};

export const companyDetailGetCompanyInformation = async (data, token) => {
    CheckApi('getCompanyInformation', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/companyDetail/getCompanyInformation",
        data,
        token
    });
    CheckApi('dataRes-getCompanyInformation', dataRes)
    return dataRes;
};

export const companyDetailUpdateCompanyProfile = async (data, token) => {
    CheckApi('updateCompanyProfile', data)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/companyDetail/updateCompanyProfile",
        data,
        token
    });
    CheckApi('dataRes-updateCompanyProfile', dataRes)
    return dataRes;
};

export const companyDetailGetcheckYachtNameandBranchName = async (data, token) => {
    CheckApi('getcheckYachtNameandBranchName', data)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/companyDetail/getcheckYachtNameandBranchName",
        data,
        token
    });
    CheckApi('dataRes-getcheckYachtNameandBranchName', dataRes)
    return dataRes;
};