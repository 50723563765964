import { all, fork, put, takeEvery } from "redux-saga/effects";
// import { useIntl } from 'react-intl';
import {
    BOOKING_LOAD_BOOKING_LIST,
    // BOOKING_LOAD_BOOKING_LIST_SUCCESS
} from "../actions";
import {
    bookingGetBooking,
    bookingGetPaymentBookingbyYacht,
} from "../../helpers/booking-api";
import { NotificationManager } from '../../components/common/react-notifications';
import {
    // getBooklist,
    getBooklistSuccess,
    getAllPaymentBookingSuccess,
} from "./actions";

// const intl = useIntl();
const PullNotificationManager = async (text) => {
    // eslint-disable-next-line no-return-await
    await NotificationManager.warning(text, 'Error', 3000, null, null, '');
}
//  booking
function* bookingLoadBooklist({ payload, token }) {
    try {
        const dataRes = yield bookingGetBooking(payload, token);
        const dataResPaymentBooking = yield bookingGetPaymentBookingbyYacht(payload, token);
        if (dataRes.status === 'success' && dataResPaymentBooking.status === 'success') {
            yield put(getBooklistSuccess(dataRes.data));
            yield put(getAllPaymentBookingSuccess(dataResPaymentBooking.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield PullNotificationManager("Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

// function* companyDetailLoadCompanyInformation({ payload, token }) {
//     try {
//         // console.log('companyDetailLoadRoleYacht', payload, token)
//         const dataRes = yield companyDetailGetCompanyInformation(payload, token);
//         if (dataRes.status === 'success') {
//             yield put(getCompanyInformationSuccess(dataRes.data));
//         } else
//             if (dataRes.status === 'error' && dataRes.auth === false) {
//                 yield NotificationManager.warning("TIME OUT! Please Login again.", 'Error', 3000, null, null, '');
//                 // yield put(settingErrorMessage("TIME OUT! Please Login again."))
//             } else {
//                 yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
//                 // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
//             }
//     } catch (error) {
//         yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
//         // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
//     }
// }

// function* companyDetailUpdateUser({ payload, token }) {
//     try {
//         const dataRes = yield companyDetailUpdateCompanyProfile(payload, token);
//         if (dataRes.status === 'success') {
//             yield put(getYacht({ yacht_id: payload.yacht_id }, token));
//             yield put(getCompanyInformation({ yacht_id: payload.yacht_id }, token));
//             yield put(updateCompanyProfileSuccess())
//         } else
//             if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
//                 yield NotificationManager.warning("Oops! The data name is duplicated. Please change the data name.", 'Error', 3000, null, null, '');
//                 // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
//             }
//             else if (dataRes.status === 'error' && dataRes.auth === false) {
//                 yield NotificationManager.warning("TIME OUT! Please Login again.", 'Error', 3000, null, null, '');
//                 // yield put(settingErrorMessage("TIME OUT! Please Login again."))
//             } else {
//                 yield NotificationManager.warning(dataRes.msg, 'Error', 3000, null, null, '');
//                 // yield put(settingErrorMessage(`${dataRes.msg}`));
//             }

//     } catch (error) {
//         yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
//         // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
//     }
// }


// Booklist
export function* watchLoadBooklist() {
    yield takeEvery(
        BOOKING_LOAD_BOOKING_LIST,
        bookingLoadBooklist
    );
}
// export function* watchLoadCompanyInformation() {
//     yield takeEvery(
//         COMPANY_DETAIL_LOAD_COMPANY_INFORMATION,
//         companyDetailLoadCompanyInformation
//     );
// }
// export function* watchUpdateUser() {
//     yield takeEvery(
//         COMPANY_DETAIL_UPDATE_COMPANY_PROFILE,
//         companyDetailUpdateUser
//     );
// }

export default function* rootSaga() {
    yield all([
        //  Book list
        fork(watchLoadBooklist),
        // fork(watchLoadCompanyInformation),
        // fork(watchUpdateUser),
    ])
}