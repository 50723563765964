// eslint-disable-next-line import/no-cycle
import {

    PROMOTION_LOAD_PROMOTION_CATEGORY,
    PROMOTION_LOAD_PROMOTION_CATEGORY_SUCCESS,
    PROMOTION_CREATE_PROMOTION_CATEGORY,
    PROMOTION_CREATE_PROMOTION_CATEGORY_SUCCESS,
    PROMOTION_UPDATE_PROMOTION_CATEGORY,
    PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS,
    PROMOTION_DELETE_PROMOTION_CATEGORY,
    PROMOTION_DELETE_PROMOTION_CATEGORY_SUCCESS,

    PROMOTION_LOAD_CUSTOMER_TYPE,
    PROMOTION_LOAD_CUSTOMER_TYPE_SUCCESS,
    PROMOTION_CREATE_CUSTOMER_TYPE,
    PROMOTION_CREATE_CUSTOMER_TYPE_SUCCESS,
    PROMOTION_UPDATE_CUSTOMER_TYPE,
    PROMOTION_UPDATE_CUSTOMER_TYPE_SUCCESS,
    PROMOTION_DELETE_CUSTOMER_TYPE,
    PROMOTION_DELETE_CUSTOMER_TYPE_SUCCESS,

    PROMOTION_LOAD_PROMOTION_LIST,
    PROMOTION_LOAD_PROMOTION_LIST_SUCCESS,
    PROMOTION_CREATE_PROMOTION_LIST,
    PROMOTION_CREATE_PROMOTION_LIST_SUCCESS,
    PROMOTION_UPDATE_PROMOTION_LIST,
    PROMOTION_UPDATE_PROMOTION_LIST_SUCCESS,
    PROMOTION_DELETE_PROMOTION_LIST,
    PROMOTION_DELETE_PROMOTION_LIST_SUCCESS,

} from '../actions';

// // -------------------- getPromotionCategory --------------------- //
export const getPromotionCategory = (data, token) => ({
    type: PROMOTION_LOAD_PROMOTION_CATEGORY,
    payload: data,
    token
});
export const getPromotionCategorySuccess = (data) => ({
    type: PROMOTION_LOAD_PROMOTION_CATEGORY_SUCCESS,
    payload: data
});

// // -------------------- addPromotionCategory --------------------- //
export const createPromotionCategory = (data, token) => ({
    type: PROMOTION_CREATE_PROMOTION_CATEGORY,
    payload: data,
    token
});
export const createPromotionCategorySuccess = () => ({
    type: PROMOTION_CREATE_PROMOTION_CATEGORY_SUCCESS,
});

// // -------------------- updatePromotionCategory --------------------- //
export const updatePromotionCategory = (data, token) => ({
    type: PROMOTION_UPDATE_PROMOTION_CATEGORY,
    payload: data,
    token
});
export const updatePromotionCategorySuccess = () => ({
    type: PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS,
});

// // -------------------- deletePromotionCategory --------------------- //
export const deletePromotionCategory = (data, token) => ({
    type: PROMOTION_DELETE_PROMOTION_CATEGORY,
    payload: data,
    token
});
export const deletePromotionCategorySuccess = () => ({
    type: PROMOTION_DELETE_PROMOTION_CATEGORY_SUCCESS,
});



// // -------------------- getCustomerType --------------------- //
export const getCustomerType = (data, token) => ({
    type: PROMOTION_LOAD_CUSTOMER_TYPE,
    payload: data,
    token
});
export const getCustomerTypeSuccess = (data) => ({
    type: PROMOTION_LOAD_CUSTOMER_TYPE_SUCCESS,
    payload: data
});

// // -------------------- addCustomerType --------------------- //
export const createCustomerType = (data, token) => ({
    type: PROMOTION_CREATE_CUSTOMER_TYPE,
    payload: data,
    token
});
export const createCustomerTypeSuccess = () => ({
    type: PROMOTION_CREATE_CUSTOMER_TYPE_SUCCESS,
});

// // -------------------- updateCustomerType --------------------- //
export const updateCustomerType = (data, token) => ({
    type: PROMOTION_UPDATE_CUSTOMER_TYPE,
    payload: data,
    token
});
export const updateCustomerTypeSuccess = () => ({
    type: PROMOTION_UPDATE_CUSTOMER_TYPE_SUCCESS,
});

// // -------------------- deleteCustomerType --------------------- //
export const deleteCustomerType = (data, token) => ({
    type: PROMOTION_DELETE_CUSTOMER_TYPE,
    payload: data,
    token
});
export const deleteCustomerTypeSuccess = () => ({
    type: PROMOTION_DELETE_CUSTOMER_TYPE_SUCCESS,
});



// // -------------------- getPromotion --------------------- //
export const getPromotionlist = (data, token) => ({
    type: PROMOTION_LOAD_PROMOTION_LIST,
    payload: data,
    token
});
export const getPromotionlistSuccess = (data) => ({
    type: PROMOTION_LOAD_PROMOTION_LIST_SUCCESS,
    payload: data
});

// // -------------------- addPromotion --------------------- //
export const createPromotionlist = (data, token) => ({
    type: PROMOTION_CREATE_PROMOTION_LIST,
    payload: data,
    token
});
export const createPromotionlistSuccess = () => ({
    type: PROMOTION_CREATE_PROMOTION_LIST_SUCCESS,
});

// // -------------------- updatePromotion --------------------- //
export const updatePromotionlist = (data, token) => ({
    type: PROMOTION_UPDATE_PROMOTION_LIST,
    payload: data,
    token
});
export const updatePromotionlistSuccess = () => ({
    type: PROMOTION_UPDATE_PROMOTION_LIST_SUCCESS,
});

// // -------------------- deletePromotion --------------------- //
export const deletePromotionlist = (data, token) => ({
    type: PROMOTION_DELETE_PROMOTION_LIST,
    payload: data,
    token
});
export const deletePromotionlistSuccess = () => ({
    type: PROMOTION_DELETE_PROMOTION_LIST_SUCCESS,
});