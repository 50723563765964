import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  LOAD_NOTIFY
  // RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  setNotify,
  // dataDataFromToken,
  // logoutUser,
  // resetPasswordSuccess,
  // resetPasswordError,
} from './actions';

import { adminRoot, currentUser } from '../../constants/defaultValues';
import { setCurrentUser } from '../../helpers/Utils';
import { signIn, userGetAnnounce } from "../../helpers/user-api";
import { NotificationManager } from '../../components/common/react-notifications';

const PullNotificationManager = async (text) => {
  // eslint-disable-next-line no-return-await
  await NotificationManager.warning(text, 'Error', 3000, null, null, '');
}


export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsyncApi = async (email, password) => {
  // eslint-disable-next-line no-return-await
  const userName = email
  // console.log('email, password), ', email, password);
  const signInData = await signIn({ userName, password });
  // const RoleCategory = await getRoleCategory(a.accessToken );
  return signInData
  // console.log('a', a)
  // .then((user) => {
  //   console.log('user', user);
  //   return user
  // })
  // .catch((error) => error);
  // .signInWithEmailAndPassword(email, password)
  // .then((user) => user)
  // .catch((error) => error);
}

// const loginWithEmailPasswordAsync = async (email, password) =>
//   // eslint-disable-next-line no-return-await
//   await auth
//     .signInWithEmailAndPassword(email, password)
//     .then((user) => user)
//     .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUserApi = yield call(loginWithEmailPasswordAsyncApi, email, password);
    // const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    // console.log('loginUserApi', loginUserApi.accessToken)
    if (loginUserApi.auth) {
      const item = { uid: loginUserApi.uid, ...currentUser };
      localStorage.setItem('token', loginUserApi.accessToken)
      // localStorage.setItem("currentLanguage", 'th');
      setCurrentUser(item);
      yield put(loginUserSuccess(loginUserApi));
      // yield put(dataDataFromToken(loginUserApi));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUserApi.msg));
      console.log("login failed error :", loginUserApi.msg);
    }
  } catch (error) {
    console.log("login error : ", error);
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  localStorage.clear();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

function* loadNotify({ payload, token }) {
  // const token = localStorage.getItem('token');
  try {
    // const dataRes = yield ServicesRoomservice.getRoomserviceDontReaded({ date: payload.date }, token);
    // const dataResTourdesk = yield ServicesTourdesk.getTourDeskDontReaded({ date: payload.date }, token);
    const dataResAnnounce = yield userGetAnnounce({ branch_id: payload.branch_id, date: payload.date, id_province: payload.id_province }, token);
    if (dataResAnnounce.status === 'success') {
      yield put(setNotify([...dataResAnnounce.data]));
    }
    // if (dataResTourdesk.status === 'success' && dataRes.status === 'success') {
    //   yield put(setNotify([...dataRes.data, ...dataResTourdesk.data, ...dataResAnnounce.data]));
    // } else if (dataResTourdesk.status === 'error') {
    //   yield put(authStatusError(`${dataRes.msg}`));
    // } else if (dataRes.status === 'error') {
    //   yield put(authStatusError(`${dataRes.msg}`));
    // } else if (dataResAnnounce.status === 'error') {
    //   yield put(authStatusError(`${dataResAnnounce.msg}`));
    // }
  } catch (error) {
    // yield put(authStatusError(`Notification fail.`));
    yield PullNotificationManager("Notification fail.")

  }
}

export function* watchLoadNotify() {
  yield takeEvery(LOAD_NOTIFY, loadNotify);
}

// export function* watchResetPassword() {
//   // eslint-disable-next-line no-use-before-define
//   yield takeEvery(RESET_PASSWORD, resetPassword);
// }

// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//   // eslint-disable-next-line no-return-await
//   return await auth
//     .confirmPasswordReset(resetPasswordCode, newPassword)
//     .then((user) => user)
//     .catch((error) => error);
// };

// function* resetPassword({ payload }) {
//   const { newPassword, resetPasswordCode } = payload;
//   try {
//     const resetPasswordStatus = yield call(
//       resetPasswordAsync,
//       resetPasswordCode,
//       newPassword
//     );
//     if (!resetPasswordStatus) {
//       yield put(resetPasswordSuccess('success'));
//     } else {
//       yield put(resetPasswordError(resetPasswordStatus.message));
//     }
//   } catch (error) {
//     yield put(resetPasswordError(error));
//   }
// }

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchLoadNotify),
    // fork(watchResetPassword),
  ]);
}
