import axios from 'axios';
import { testLocalhost } from "../constants/defaultValues";

let HEADER = {
    'Content-Type': "application/json",
    withCredentials: true,
};
// const BASE_URL = "http://localhost:5003/api-tmy-2020/asia-southeast2/";
// const BASE_URL = "https://asia-southeast2-api-tmy-2020.cloudfunctions.net/";
const Provider = async (options = { type: '', url: '', data: null, token: false }) => {
    // console.log("Provider", options);
    const { type, url, data, token } = options;
    let BASE_URL = "https://asia-southeast2-api-tmy-2020.cloudfunctions.net//";
    let dataRes;

    if (testLocalhost) {
        BASE_URL = "http://localhost:5003/api-tmy-2020/asia-southeast2/";
    } else {
        BASE_URL = "https://asia-southeast2-api-tmy-2020.cloudfunctions.net/";
    }

    if (token) {
        HEADER = {
            ...HEADER,
            'x-has-accesstoken': token
        }
    }
    const instanceMain = axios.create({
        baseURL: BASE_URL,
        headers: HEADER,
        transformResponse: [function apiData(data1) {
            const data2 = JSON.parse(data1);
            if (data2 === null) return data2;
            if (data2.msg !== undefined) {
                if (data2.msg === "ข้อมูลการ Authen ไม่ถูกต้อง") {
                    window.location = `${BASE_URL}/403`;
                }
            }
            return data2;
        }]
    });
    switch (type) {
        case "GET":
            dataRes = await instanceMain.get(url);
            break;
        case "SHOW":
            dataRes = await instanceMain.get(url);
            break;
        case "POST":
            dataRes = await instanceMain.post(url, data);
            break;
        case "PUT":
            dataRes = await instanceMain.put(url, data);
            break;
        case "DELETE":
            dataRes = await instanceMain.delete(url);
            break;
        default:
            break;
    }
    return dataRes.data;
}
export default Provider;