/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
// changeYacht

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const CHANGE_YACHT = 'CHANGE_YACHT';
export const CHANGE_USER_IMAGE = 'CHANGE_USER_IMAGE';
export const CHANGE_USER_USERNAME = 'CHANGE_USER_USERNAME';
export const CHANGE_YACHT_DETAIL_BY_COMPANY_DETAIL = "CHANGE_YACHT_DETAIL_BY_COMPANY_DETAIL";
export const LOAD_NOTIFY = "LOAD_NOTIFY";
export const LOAD_NOTIFY_SUCCESS = "LOAD_NOTIFY_SUCCESS";

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* TODOAPP */
export const TODO_GET_LIST = 'TODO_GET_LIST';
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST';
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS';
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR';
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
  'SURVEY_LIST_GET_LIST_WITH_FILTER';
export const SURVEY_LIST_GET_LIST_WITH_ORDER =
  'SURVEY_LIST_GET_LIST_WITH_ORDER';
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH';
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM';
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS';
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR';
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
  'SURVEY_LIST_SELECTED_ITEMS_CHANGE';

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS';
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS';
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_SAVE = 'SURVEY_SAVE';

// -------------select ADDRESS ----------------//
export const LOAD_COUNTRY = "LOAD_COUNTRY";
export const LOAD_COUNTRY_SUCCESS = "LOAD_COUNTRY_SUCCESS";
export const ADDRESS_LOAD_PROVICE = "ADDRESS_LOAD_PROVICE";
export const ADDRESS_LOAD_PROVICE_SUCCESS = "ADDRESS_LOAD_PROVICE_SUCCESS";
export const ADDRESS_LOAD_DISTRICT = "ADDRESS_LOAD_DISTRICT";
export const ADDRESS_LOAD_DISTRICT_SUCCESS = "ADDRESS_LOAD_DISTRICT_SUCCESS";
export const ADDRESS_LOAD_SUB_DISTRICT = "ADDRESS_LOAD_SUB_DISTRICT";
export const ADDRESS_LOAD_SUB_DISTRICT_SUCCESS = "ADDRESS_LOAD_SUB_DISTRICT_SUCCESS";
export const ADDRESS_LOAD_DISTRICT2 = "ADDRESS_LOAD_DISTRICT2";
export const ADDRESS_LOAD_DISTRICT_SUCCESS2 = "ADDRESS_LOAD_DISTRICT_SUCCESS2";
export const ADDRESS_LOAD_SUB_DISTRICT2 = "ADDRESS_LOAD_SUB_DISTRICT2";
export const ADDRESS_LOAD_SUB_DISTRICT_SUCCESS2 = "ADDRESS_LOAD_SUB_DISTRICT_SUCCESS2";


export const GET_DATA_FROM_TOKEN = 'GET_DATA_FROM_TOKEN';
/* USER */
export const SETTING_ERROR_MESSAGE = "SETTING_ERROR_MESSAGE";
export const CLOSE_SETTING_ERROR_MESSAGE = "CLOSE_SETTING_ERROR_MESSAGE";
export const USER_MANAGEMENT_LOAD_ALL_DATA = "USER_MANAGEMENT_LOAD_ALL_DATA";
export const USER_MANAGEMENT_LOAD_ALL_DATA_SUCCESS = "USER_MANAGEMENT_LOAD_ALL_DATA_SUCCESS";
export const USER_MANAGEMENT_ADD_NAME_ROLECATEGORY = 'USER_MANAGEMENT_ADD_NAME_ROLECATEGORY';
export const USER_MANAGEMENT_ADD_NAME_ROLECATEGORY_SUCCESS = "USER_MANAGEMENT_ADD_NAME_ROLECATEGORY_SUCCESS";
export const USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY = 'USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY';
export const USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY_SUCCESS = "USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY_SUCCESS";
export const USER_MANAGEMENT_LOAD_NATIONALITY = "USER_MANAGEMENT_LOAD_NATIONALITY"
// Nationality NATIONALITY
export const USER_MANAGEMENT_LOAD_NATIONALITY_SUCCESS = "USER_MANAGEMENT_LOAD_NATIONALITY_SUCCESS";
export const USER_MANAGEMENT_LOAD_ROLECATEGORY = "USER_MANAGEMENT_LOAD_ROLECATEGORY"
export const USER_MANAGEMENT_LOAD_ROLECATEGORY_SUCCESS = "USER_MANAGEMENT_LOAD_ROLECATEGORY_SUCCESS";
export const USER_MANAGEMENT_DELETE_ROLECATEGORY = "USER_MANAGEMENT_DELETE_ROLECATEGORY"
export const USER_MANAGEMENT_DELETE_ROLECATEGORY_SUCCESS = "USER_MANAGEMENT_DELETE_ROLECATEGORY_SUCCESS";


export const USER_MANAGEMENT_CREATE_USER_PASS = "USER_MANAGEMENT_CREATE_USER_PASS"
export const USER_MANAGEMENT_CREATE_USER_PASS_SUCCESS = "USER_MANAGEMENT_CREATE_USER_PASS_SUCCESS";
export const USER_MANAGEMENT_RESET_USER_PASS = "USER_MANAGEMENT_RESET_USER_PASS";
export const USER_MANAGEMENT_RESET_USER_PASS_SUCCESS = "USER_MANAGEMENT_RESET_USER_PASS_SUCCESS";


export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const USER_MANAGEMENT_UPDATE_USER = 'USER_MANAGEMENT_UPDATE_USER';
export const USER_MANAGEMENT_UPDATE_USER_SUCCESS = 'USER_MANAGEMENT_UPDATE_USER_SUCCESS';
// /* ------------------ROLE MANAGEMENT------------------- */
export const ROLE_MANAGEMENT_LOAD_ALL_DATA = "ROLE_MANAGEMENT_LOAD_ALL_DATA";
export const ROLE_MANAGEMENT_LOAD_ALL_DATA_SUCCESS = "ROLE_MANAGEMENT_LOAD_ALL_DATA_SUCCESS";
export const ROLE_MANAGEMENT_CREATE_ROLE = "ROLE_MANAGEMENT_CREATE_ROLE";
export const ROLE_MANAGEMENT_CREATE_ROLE_SUCCESS = "ROLE_MANAGEMENT_CREATE_ROLE_SUCCESS";
export const ROLE_MANAGEMENT_UPDATE_ROLE = "ROLE_MANAGEMENT_UPDATE_ROLE";
export const ROLE_MANAGEMENT_UPDATE_ROLE_SUCCESS = "ROLE_MANAGEMENT_UPDATE_ROLE_SUCCESS";
export const ROLE_MANAGEMENT_LOAD_ROLE = "ROLE_MANAGEMENT_LOAD_ROLE"
export const ROLE_MANAGEMENT_LOAD_ROLE_SUCCESS = "ROLE_MANAGEMENT_LOAD_ROLE_SUCCESS";
export const ROLE_MANAGEMENT_DELETE_ROLE = "ROLE_MANAGEMENT_DELETE_ROLE"
export const ROLE_MANAGEMENT_DELETE_ROLE_SUCCESS = "ROLE_MANAGEMENT_DELETE_ROLE_SUCCESS";
export const ROLE_MANAGEMENT_CREATE_ROLELIST = "ROLE_MANAGEMENT_CREATE_ROLELIST"
export const ROLE_MANAGEMENT_CREATE_ROLELIST_SUCCESS = "ROLE_MANAGEMENT_CREATE_ROLELIST_SUCCESS";
export const ROLE_MANAGEMENT_LOAD_ROLELIST = "ROLE_MANAGEMENT_LOAD_ROLELIST"
export const ROLE_MANAGEMENT_LOAD_ROLELIST_SUCCESS = "ROLE_MANAGEMENT_LOAD_ROLELIST_SUCCESS";
export const ROLE_MANAGEMENT_DELETE_ROLELIST = "ROLE_MANAGEMENT_DELETE_ROLELIST"
export const ROLE_MANAGEMENT_DELETE_ROLELIST_SUCCESS = "ROLE_MANAGEMENT_DELETE_ROLELIST_SUCCESS";
export const ROLE_MANAGEMENT_DELETE_ROLELIST_USER = "ROLE_MANAGEMENT_DELETE_ROLELIST_USER"
export const ROLE_MANAGEMENT_DELETE_ROLELIST_USER_SUCCESS = "ROLE_MANAGEMENT_DELETE_ROLELIST_USER_SUCCESS";
export const ROLE_MANAGEMENT_UPDATE_ROLELIST = "ROLE_MANAGEMENT_UPDATE_ROLELIST"
export const ROLE_MANAGEMENT_UPDATE_ROLELIST_SUCCESS = "ROLE_MANAGEMENT_UPDATE_ROLELIST_SUCCESS";
export const ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT = "ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT"
export const ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT_SUCCESS = "ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT_SUCCESS";
// /* ------------------Company Detail------------------- */
export const COMPANY_DETAIL_LOAD_YACHT = "COMPANY_DETAIL_LOAD_YACHT";
export const COMPANY_DETAIL_LOAD_YACHT_SUCCESS = "COMPANY_DETAIL_LOAD_YACHT_SUCCESS";
export const COMPANY_DETAIL_LOAD_COMPANY_INFORMATION = "COMPANY_DETAIL_LOAD_COMPANY_INFORMATION";
export const COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS = "COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS";
export const COMPANY_DETAIL_UPDATE_COMPANY_PROFILE = "COMPANY_DETAIL_UPDATE_COMPANY_PROFILE";
export const COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS = "COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS";
// /* ------------------Yacht Information------------------- */
export const YACHT_INFOMATION_LOAD_YACHTINFO = "YACHT_INFOMATION_LOAD_YACHTINFO";
export const YACHT_INFOMATION_LOAD_YACHTINFO_SUCCESS = "YACHT_INFOMATION_LOAD_YACHTINFO_SUCCESS";
export const YACHT_INFOMATION_CREATE_YACHTINFO = "YACHT_INFOMATION_CREATE_YACHTINFO";
export const YACHT_INFOMATION_CREATE_YACHTINFO_SUCCESS = "YACHT_INFOMATION_CREATE_YACHTINFO_SUCCESS";
export const YACHT_INFOMATION_UPDATE_YACHTINFO = "YACHT_INFOMATION_UPDATE_YACHTINFO";
export const YACHT_INFOMATION_UPDATE_YACHTINFO_SUCCESS = "YACHT_INFOMATION_UPDATE_YACHTINFO_SUCCESS";
export const YACHT_INFOMATION_DELETE_YACHTINFO = "YACHT_INFOMATION_DELETE_YACHTINFO";
export const YACHT_INFOMATION_DELETE_YACHTINFO_SUCCESS = "YACHT_INFOMATION_DELETE_YACHTINFO_SUCCESS";
export const YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS = "YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS";
export const YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS_SUCCESS = "YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS_SUCCESS";
// ChangeStatusYachtInfo 
// /* ------------------ promotion ------------------- */
export const PROMOTION_LOAD_PROMOTION_CATEGORY = "PROMOTION_LOAD_PROMOTION_CATEGORY";
export const PROMOTION_LOAD_PROMOTION_CATEGORY_SUCCESS = "PROMOTION_LOAD_PROMOTION_CATEGORY_SUCCESS";
export const PROMOTION_CREATE_PROMOTION_CATEGORY = "PROMOTION_CREATE_PROMOTION_CATEGORY";
export const PROMOTION_CREATE_PROMOTION_CATEGORY_SUCCESS = "PROMOTION_CREATE_PROMOTION_CATEGORY_SUCCESS";
export const PROMOTION_UPDATE_PROMOTION_CATEGORY = "PROMOTION_UPDATE_PROMOTION_CATEGORY";
export const PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS = "PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS";
export const PROMOTION_DELETE_PROMOTION_CATEGORY = "PROMOTION_DELETE_PROMOTION_CATEGORY";
export const PROMOTION_DELETE_PROMOTION_CATEGORY_SUCCESS = "PROMOTION_DELETE_PROMOTION_CATEGORY_SUCCESS";

export const PROMOTION_LOAD_CUSTOMER_TYPE = "PROMOTION_LOAD_CUSTOMER_TYPE";
export const PROMOTION_LOAD_CUSTOMER_TYPE_SUCCESS = "PROMOTION_LOAD_CUSTOMER_TYPE_SUCCESS";
export const PROMOTION_CREATE_CUSTOMER_TYPE = "PROMOTION_CREATE_CUSTOMER_TYPE";
export const PROMOTION_CREATE_CUSTOMER_TYPE_SUCCESS = "PROMOTION_CREATE_CUSTOMER_TYPE_SUCCESS";
export const PROMOTION_UPDATE_CUSTOMER_TYPE = "PROMOTION_UPDATE_CUSTOMER_TYPE";
export const PROMOTION_UPDATE_CUSTOMER_TYPE_SUCCESS = "PROMOTION_UPDATE_CUSTOMER_TYPE_SUCCESS";
export const PROMOTION_DELETE_CUSTOMER_TYPE = "PROMOTION_DELETE_CUSTOMER_TYPE";
export const PROMOTION_DELETE_CUSTOMER_TYPE_SUCCESS = "PROMOTION_DELETE_CUSTOMER_TYPE_SUCCESS";

export const PROMOTION_LOAD_PROMOTION_LIST = "PROMOTION_LOAD_PROMOTION_LIST";
export const PROMOTION_LOAD_PROMOTION_LIST_SUCCESS = "PROMOTION_LOAD_PROMOTION_LIST_SUCCESS";
export const PROMOTION_CREATE_PROMOTION_LIST = "PROMOTION_CREATE_PROMOTION_LIST";
export const PROMOTION_CREATE_PROMOTION_LIST_SUCCESS = "PROMOTION_CREATE_PROMOTION_LIST_SUCCESS";
export const PROMOTION_UPDATE_PROMOTION_LIST = "PROMOTION_UPDATE_PROMOTION_LIST";
export const PROMOTION_UPDATE_PROMOTION_LIST_SUCCESS = "PROMOTION_UPDATE_PROMOTION_LIST_SUCCESS";
export const PROMOTION_DELETE_PROMOTION_LIST = "PROMOTION_DELETE_PROMOTION_LIST";
export const PROMOTION_DELETE_PROMOTION_LIST_SUCCESS = "PROMOTION_DELETE_PROMOTION_LIST_SUCCESS";

// export const PROMOTION_UPDATE_PROMOTION_CATEGORY = "PROMOTION_UPDATE_PROMOTION_CATEGORY";
// export const PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS = "PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS";

//  Booking
export const BOOKING_LOAD_BOOKING_LIST = "BOOKING_LOAD_BOOKING_LIST";
export const BOOKING_LOAD_BOOKING_LIST_SUCCESS = "BOOKING_LOAD_BOOKING_LIST_SUCCESS";
export const BOOKING_LOAD_ALL_PAYMENT_BOOKING_LIST_SUCCESS = "BOOKING_LOAD_ALL_PAYMENT_BOOKING_LIST_SUCCESS";

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './todo/actions';
export * from './chat/actions';
export * from './surveyList/actions';
export * from './surveyDetail/actions';
export * from './user/actions';
export * from './company/actions';
export * from './yachtInfo/actions';
export * from './promotion/actions';
export * from './booking/actions';
// export * from './address/actions';
