// eslint-disable-next-line import/no-cycle
import {

    COMPANY_DETAIL_LOAD_YACHT,
    COMPANY_DETAIL_LOAD_YACHT_SUCCESS,
    COMPANY_DETAIL_LOAD_COMPANY_INFORMATION,
    COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS,
    COMPANY_DETAIL_UPDATE_COMPANY_PROFILE,
    COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS,

} from '../actions';

// // -------------------- Yacht --------------------- //
export const getYacht = (data, token) => ({
    type: COMPANY_DETAIL_LOAD_YACHT,
    payload: data,
    token
});
export const getYachtSuccess = (data) => ({
    type: COMPANY_DETAIL_LOAD_YACHT_SUCCESS,
    payload: data
});
// // ------------------ Company Information ------------------- //
export const getCompanyInformation = (data, token) => ({
    type: COMPANY_DETAIL_LOAD_COMPANY_INFORMATION,
    payload: data,
    token
});
export const getCompanyInformationSuccess = (data) => ({
    type: COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS,
    payload: data
});
// // ------------------ Update Company Profile ------------------- //
export const updateCompanyProfile = (data, token) => ({
    type: COMPANY_DETAIL_UPDATE_COMPANY_PROFILE,
    payload: data,
    token
});
export const updateCompanyProfileSuccess = () => ({
    type: COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS
});




