import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import userManagementSagas from './user/saga';
import companyDetailSagas from './company/saga';
import yachtInfoSagas from './yachtInfo/saga';
import promotionSagas from './promotion/saga';
import bookingSagas from './booking/saga';
// import AddressOptionSagas from './address/saga'; 

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    userManagementSagas(),
    companyDetailSagas(),
    yachtInfoSagas(),
    promotionSagas(),
    bookingSagas(),
    // AddressOptionSagas(),
  ]);
}
