import Provider from "./Provider-Api";
import CheckApi from "./check-api";


export const promotionGetPromotionCategory = async (data, token) => {
    CheckApi('getPromotionCategory', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/getPromotionCategory",
        data,
        token
    });
    CheckApi('dataRes-getPromotionCategory', dataRes)
    return dataRes;
};

export const promotionAddPromotionCategory = async (data, token) => {
    CheckApi('addPromotionCategory', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/addPromotionCategory",
        data,
        token
    });
    CheckApi('dataRes-addPromotionCategory', dataRes)
    return dataRes;
};

export const promotionUpdatePromotionCategory = async (data, token) => {
    CheckApi('updatePromotionCategory', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/updatePromotionCategory",
        data,
        token
    });
    CheckApi('dataRes-updatePromotionCategory', dataRes)
    return dataRes;
};

export const promotionDeletePromotionCategory = async (item, token) => {
    CheckApi('deletePromotionCategory', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/deletePromotionCategory",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deletePromotionCategory', dataRes)
    return dataRes;
};

export const promotionGetCustomerType = async (data, token) => {
    CheckApi('getCustomerType', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/getCustomerType",
        data,
        token
    });
    CheckApi('dataRes-getCustomerType', dataRes)
    return dataRes;
};

export const promotionAddCustomerType = async (data, token) => {
    CheckApi('addCustomerType', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/addCustomerType",
        data,
        token
    });
    CheckApi('dataRes-addCustomerType', dataRes)
    return dataRes;
};

export const promotionUpdateCustomerType = async (data, token) => {
    CheckApi('updateCustomerType', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/updateCustomerType",
        data,
        token
    });
    CheckApi('dataRes-updateCustomerType', dataRes)
    return dataRes;
};

export const promotionGetCheckDataDeleteCustomerType = async (data, token) => {
    CheckApi('getCheckDataDeleteCustomerType', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/getCheckDataDeleteCustomerType",
        data,
        token
    });
    CheckApi('dataRes-getCheckDataDeleteCustomerType', dataRes)
    return dataRes;
};

export const promotionDeleteCustomerType = async (item, token) => {
    CheckApi('deleteCustomerType', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/deleteCustomerType",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteCustomerType', dataRes)
    return dataRes;
};

export const promotionGetPromotion = async (data, token) => {
    CheckApi('getPromotion', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/getPromotion",
        data,
        token
    });
    CheckApi('dataRes-getPromotion', dataRes)
    return dataRes;
};

export const promotionAddPromotion = async (data, token) => {
    CheckApi('addPromotion', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/addPromotion",
        data,
        token
    });
    CheckApi('dataRes-addPromotion', dataRes)
    return dataRes;
};

export const promotionUpdatePromotion = async (data, token) => {
    CheckApi('updatePromotion', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/updatePromotion",
        data,
        token
    });
    CheckApi('dataRes-updatePromotion', dataRes)
    return dataRes;
};

export const promotionGetCheckDataDeletePromotion = async (data, token) => {
    CheckApi('getCheckDataDeletePromotion', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/getCheckDataDeletePromotion",
        data,
        token
    });
    CheckApi('dataRes-getCheckDataDeletePromotion', dataRes)
    return dataRes;
};

export const promotionDeletePromotion = async (item, token) => {
    CheckApi('deletePromotion', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/promotion/deletePromotion",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deletePromotion', dataRes)
    return dataRes;
};


