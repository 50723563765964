import Provider from "./Provider-Api";
import CheckApi from "./check-api";


export const signIn = async (user) => {
  CheckApi('signIn', user)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/signin",
    data: user,
    token: false
  });
  CheckApi('dataRes-signIn', dataRes)
  return dataRes;
};

export const userGetRoleCategory = async (data, token) => {
  CheckApi('getRoleCategory', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getRoleCategory",
    data,
    token
  });
  CheckApi('dataRes-getRoleCategory', dataRes)
  return dataRes;
};

export const userAddRoleCategory = async (data, token) => {
  CheckApi('addRoleCategory', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/addRoleCategory",
    data,
    token
  });
  CheckApi('dataRes-addRoleCategory', dataRes)
  return dataRes;
};

export const userDeleteRoleCategory = async (item, token) => {
  CheckApi('deleteRoleCategory', item)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/deleteRoleCategory",
    data: {
      item
    },
    token
  });
  CheckApi('dataRes-deleteRoleCategory', dataRes)
  return dataRes;
};

export const userUpdateRoleCategory = async (data, token) => {
  CheckApi('updateRoleCategory', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/updateRoleCategory",
    data,
    token
  });
  CheckApi('dataRes-updateRoleCategory', dataRes)
  return dataRes;
};

export const userGetUser = async (data, token) => {
  CheckApi('getUser', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getUser",
    data,
    token
  });
  CheckApi('dataRes-getUser', dataRes)
  return dataRes;
};

export const userGetCheckEmailUser = async (data, token) => {
  CheckApi('getCheckEmailUser', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getCheckEmailUser",
    data,
    token
  });
  CheckApi('dataRes-getCheckEmailUser', dataRes)
  return dataRes;
};

export const userCreateUser = async (data, token) => {
  CheckApi('createUser', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/createUser",
    data,
    token
  });
  CheckApi('dataRes-createUser', dataRes)
  return dataRes;
};

export const userDeleteUser = async (item, token) => {
  CheckApi('deleteUser', item)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/deleteUser",
    data: {
      item
    },
    token
  });
  CheckApi('dataRes-deleteUser', dataRes)
  return dataRes;
};

export const userUpdateUser = async (data, token) => {
  CheckApi('updateUser', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/updateUser",
    data,
    token
  });
  CheckApi('dataRes-updateUser', dataRes)
  return dataRes;
};

export const userCreateUserPassword = async (data, token) => {
  CheckApi('createUserPassword', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/createUserPassword",
    data,
    token
  });
  CheckApi('dataRes-createUserPassword', dataRes)
  return dataRes;
};

export const userResetPassword = async (data, token) => {
  CheckApi('resetPassword', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/resetPassword",
    data,
    token
  });
  CheckApi('dataRes-resetPassword', dataRes)
  return dataRes;
};

export const userAddRole = async (data, token) => {
  CheckApi('resetPaaddRolessword', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/addRole",
    data,
    token
  });
  CheckApi('dataRes-addRole', dataRes)
  return dataRes;
};

export const userGetRole = async (data, token) => {
  CheckApi('getRole', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getRole",
    data,
    token
  });
  CheckApi('dataRes-getRole', dataRes)
  return dataRes;
};

export const userDeleteRole = async (item, token) => {
  CheckApi('deleteRole', item)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/deleteRole",
    data: {
      item
    },
    token
  });
  CheckApi('dataRes-deleteRole', dataRes)
  return dataRes;
};

export const userUpdateRole = async (data, token) => {
  CheckApi('updateRole', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/updateRole",
    data,
    token
  });
  CheckApi('dataRes-updateRole', dataRes)
  return dataRes;
};

export const userAddRolelist = async (data, token) => {
  CheckApi('addRolelist', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/addRolelist",
    data,
    token
  });
  CheckApi('dataRes-addRolelist', dataRes)
  return dataRes;
};

export const userGetRolelist = async (data, token) => {
  CheckApi('getRolelist', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getRolelist",
    data,
    token
  });
  CheckApi('dataRes-getRolelist', dataRes)
  return dataRes;
};

export const userUpdateRolelist = async (data, token) => {
  CheckApi('updateRolelist', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/updateRolelist",
    data,
    token
  });
  CheckApi('dataRes-updateRolelist', dataRes)
  return dataRes;
};

export const userDeleteRolelist = async (item, token) => {
  CheckApi('deleteRolelist', item)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/deleteRolelist",
    data: {
      item
    },
    token
  });
  CheckApi('dataRes-deleteRolelist', dataRes)
  return dataRes;
};

export const userDeleteUserRolelist = async (item, token) => {
  CheckApi('deleteUserRolelist', item)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/deleteUserRolelist",
    data: {
      item
    },
    token
  });
  CheckApi('dataRes-deleteUserRolelist', dataRes)
  return dataRes;
};

export const userCheckRoleCategory = async (data, token) => {
  CheckApi('checkRoleCategory', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getcheckRoleCategory",
    data,
    token
  });
  CheckApi('dataRes-checkRoleCategory', dataRes)
  return dataRes;
};

export const userSendEmailVerify = async (data, token) => {
  CheckApi('sendEmailVerify', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/sendEmailVerify",
    data,
    token
  });
  CheckApi('dataRes-sendEmailVerify', dataRes)
  return dataRes;
};

export const userVerify = async (token) => {
  CheckApi('verify', token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/verify",
    token
  });
  CheckApi('dataRes-verify', dataRes)
  return dataRes;
};

export const useSendEmailForgetPassword = async (data) => {
  CheckApi('sendEmailForgetPassword', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/sendEmailForgetPassword",
    data,
    token: false
  });
  CheckApi('dataRes-sendEmailForgetPassword', dataRes)
  return dataRes;
};

export const userResetPasswordWithToken = async (data, token) => {
  CheckApi('resetPasswordWithToken', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/resetPasswordWithToken",
    data,
    token
  });
  CheckApi('dataRes-resetPasswordWithToken', dataRes)
  return dataRes;
};

export const userUpdateUserInTabProfile = async (data, token) => {
  CheckApi('updateUserInTabProfile', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/updateUserInTabProfile",
    data,
    token
  });
  CheckApi('dataRes-updateUserInTabProfile', dataRes)
  return dataRes;
};

export const userChangePassword = async (data, token) => {
  CheckApi('changePassword', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/changePassword",
    data,
    token
  });
  CheckApi('dataRes-changePassword', dataRes)
  return dataRes;
};

export const userEditPictureProfile = async (data, token) => {
  CheckApi('editPictureProfile', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/editPictureProfile",
    data,
    token
  });
  CheckApi('dataRes-editPictureProfile', dataRes)
  return dataRes;
};

export const getDataToken = async (token) => {
  // CheckApi('getDataToken', token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getDataToken",
    token
  });
  CheckApi('dataRes-getDataToken', dataRes)
  return dataRes;
};

export const userGetYachtAgentMapping = async (data, token) => {
  CheckApi('getYachtAgentMapping', data, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getYachtAgentMapping",
    data,
    token
  });
  CheckApi('dataRes-getYachtAgentMapping', dataRes)
  return dataRes;
};

export const userAddYachtAgentMapping = async (data, token) => {
  CheckApi('addYachtAgentMapping', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/addYachtAgentMapping",
    data,
    token
  });
  CheckApi('dataRes-addYachtAgentMapping', dataRes)
  return dataRes;
};

export const userUpdateYachtAgentMapping = async (data, token) => {
  CheckApi('updateYachtAgentMapping', data)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/updateYachtAgentMapping",
    data,
    token
  });
  CheckApi('dataRes-updateYachtAgentMapping', dataRes)
  return dataRes;
};

export const userDeleteYachtAgentMapping = async (item, token) => {
  CheckApi('deleteYachtAgentMapping', item)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/deleteYachtAgentMapping",
    data: {
      item
    },
    token
  });
  CheckApi('dataRes-deleteYachtAgentMapping', dataRes)
  return dataRes;
};

export const userGetRolelistByRoleCategory = async (item, token) => {
  CheckApi('getRolelistByRoleCategory', item, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getRolelistByRoleCategory",
    data: item,
    token
  });
  CheckApi('dataRes-getRolelistByRoleCategory', dataRes)
  return dataRes;
};

export const userGetAnnounce = async (item, token) => {
  CheckApi('getAnnounce', item, token)
  // const dataRes = await 
  const dataRes = await Provider({
    type: "POST",
    url: "/user/getAnnounce",
    data: item,
    token
  });
  CheckApi('dataRes-getAnnounce', dataRes)
  return dataRes;
};

export const signIn2 = async () => {
  // const dataRes = await 
  // const dataRes = await Provider.api({
  //   type: "POST",
  //   url: "chat/getContact",
  //   data,
  //   token: false getRolelistByRoleCategory
  // });
  return "user";
};