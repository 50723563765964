// import { defaultLocale, localeOptions } from '../../constants/defaultValues'

import {
	// CHANGE_LOCALE,
	LOGOUT_USER,
	SETTING_ERROR_MESSAGE,
	CLOSE_SETTING_ERROR_MESSAGE,

	// UPDATE_HOTEL,
	// UPDATE_HOTEL_SUCCESS,

	USER_MANAGEMENT_LOAD_NATIONALITY,
	USER_MANAGEMENT_LOAD_NATIONALITY_SUCCESS,
	// USER_MANAGEMENT_ADD_NAME_DEPARTMENT,
	// USER_MANAGEMENT_ADD_NAME_DEPARTMENT_SUCCESS,
	// USER_MANAGEMENT_LOAD_DEPARTMENT,
	// USER_MANAGEMENT_LOAD_DEPARTMENT_SUCCESS,
	// USER_MANAGEMENT_DELETE_DEPARTMENT,
	// USER_MANAGEMENT_UPDATE_NAME_DEPARTMENT,
	// USER_MANAGEMENT_UPDATE_NAME_DEPARTMENT_SUCCESS,

	USER_MANAGEMENT_ADD_NAME_ROLECATEGORY,
	USER_MANAGEMENT_ADD_NAME_ROLECATEGORY_SUCCESS,
	USER_MANAGEMENT_LOAD_ROLECATEGORY,
	USER_MANAGEMENT_LOAD_ROLECATEGORY_SUCCESS,
	USER_MANAGEMENT_DELETE_ROLECATEGORY,
	// USER_MANAGEMENT_DELETE_ROLECATEGORY_SUCCESS,
	USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY,
	USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY_SUCCESS,

	// USER_MANAGEMENT_ADD_NAME_POSITION,
	// USER_MANAGEMENT_ADD_NAME_POSITION_SUCCESS,
	// USER_MANAGEMENT_UPDATE_NAME_POSITION,
	// USER_MANAGEMENT_UPDATE_NAME_POSITION_SUCCESS,
	// USER_MANAGEMENT_LOAD_POSITION,
	// USER_MANAGEMENT_LOAD_POSITION_SUCCESS,
	// USER_MANAGEMENT_DELETE_POSITION,
	// USER_MANAGEMENT_DELETE_POSITION_SUCCESS,

	CREATE_USER,
	// CREATE_USER_SUCCESS,
	LOAD_USER,
	LOAD_USER_SUCCESS,
	DELETE_USER,
	// DELETE_USER_SUCCESS,
	USER_MANAGEMENT_UPDATE_USER,
	// USER_MANAGEMENT_UPDATE_USER_SUCCESS,
	USER_MANAGEMENT_CREATE_USER_PASS,
	// USER_MANAGEMENT_CREATE_USER_PASS_SUCCESS,
	USER_MANAGEMENT_RESET_USER_PASS,
	USER_MANAGEMENT_RESET_USER_PASS_SUCCESS,

	ROLE_MANAGEMENT_CREATE_ROLE,
	ROLE_MANAGEMENT_CREATE_ROLE_SUCCESS,
	ROLE_MANAGEMENT_LOAD_ROLE,
	ROLE_MANAGEMENT_LOAD_ROLE_SUCCESS,
	ROLE_MANAGEMENT_DELETE_ROLE,
	// ROLE_MANAGEMENT_DELETE_ROLE_SUCCESS,
	ROLE_MANAGEMENT_UPDATE_ROLE,

	ROLE_MANAGEMENT_CREATE_ROLELIST,
	ROLE_MANAGEMENT_LOAD_ROLELIST,
	ROLE_MANAGEMENT_LOAD_ROLELIST_SUCCESS,
	ROLE_MANAGEMENT_DELETE_ROLELIST,
	ROLE_MANAGEMENT_UPDATE_ROLELIST,
	ROLE_MANAGEMENT_DELETE_ROLELIST_USER,

	ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT,
	ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT_SUCCESS,


} from '../actions';

const INIT_STATE = {
	// locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale,
	optioinNationality: [],
	roleCategory: [],
	optionRoleCategory: [],

	position: [],
	optionPosition: [],

	user: [],
	optionUser: [],
	resetUser: false,

	role: [],
	optionRole: [],

	permission: [],
	rolelist: [],
	rolelistObject: {},

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		// case CHANGE_LOCALE:
		// 	return { ...state, locale: action.payload };
		case SETTING_ERROR_MESSAGE:
			return { ...state, loading: false, msgError: action.payload, alrtError: true };
		case CLOSE_SETTING_ERROR_MESSAGE:
			return { ...state, loading: false, msgError: '', alrtError: false }

		case USER_MANAGEMENT_LOAD_NATIONALITY:
			return { ...state, loading: true, }
		case USER_MANAGEMENT_LOAD_NATIONALITY_SUCCESS:
			return { ...state, loading: false, optioinNationality: action.payload }

		case USER_MANAGEMENT_ADD_NAME_ROLECATEGORY:
			return { ...state, loading: true }
		case USER_MANAGEMENT_ADD_NAME_ROLECATEGORY_SUCCESS:
			return { ...state, loading: false }
		case USER_MANAGEMENT_LOAD_ROLECATEGORY:
			return { ...state, loading: true }
		case USER_MANAGEMENT_LOAD_ROLECATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
				roleCategory: action.payload,
				optionRoleCategory: action.payloadlist
			}
		case USER_MANAGEMENT_DELETE_ROLECATEGORY:
			return { ...state, loading: true }
		case USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY:
			return { ...state, loading: true }
		case USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY_SUCCESS:
			return { ...state, loading: false }

		// case USER_MANAGEMENT_ADD_NAME_POSITION:
		// 	return { ...state, loading: true }
		// case USER_MANAGEMENT_ADD_NAME_POSITION_SUCCESS:
		// 	return { ...state, loading: false }
		// case USER_MANAGEMENT_LOAD_POSITION:
		// 	return { ...state, loading: true }
		// case USER_MANAGEMENT_LOAD_POSITION_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		position: action.payload,
		// 		optionPosition: action.payloadlist
		// 	}
		// case USER_MANAGEMENT_UPDATE_NAME_POSITION:
		// 	return { ...state, loading: true }
		// case USER_MANAGEMENT_UPDATE_NAME_POSITION_SUCCESS:
		// 	return {
		// 		...state,
		// 		loading: false,
		// 		position: action.payload,
		// 		optionPosition: action.payloadlist
		// 	}
		// case USER_MANAGEMENT_DELETE_POSITION:
		// 	return { ...state, loading: true }

		case CREATE_USER:
			return { ...state, loading: true }
		case LOAD_USER:
			return { ...state, loading: true }
		case LOAD_USER_SUCCESS:
			return { ...state, loading: false, user: action.payload, optionUser: action.payloadlist }
		case DELETE_USER:
			return { ...state, loading: true }
		case USER_MANAGEMENT_UPDATE_USER:
			return { ...state, loading: true }
		case USER_MANAGEMENT_CREATE_USER_PASS:
			return { ...state, loading: true }
		case USER_MANAGEMENT_RESET_USER_PASS:
			return { ...state, loading: true, resetUser: false }
		case USER_MANAGEMENT_RESET_USER_PASS_SUCCESS:
			return { ...state, loading: false, resetUser: action.payload }

		// // ---------- Role ----------//
		case ROLE_MANAGEMENT_CREATE_ROLE:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_CREATE_ROLE_SUCCESS:
			return { ...state, loading: false }
		case ROLE_MANAGEMENT_LOAD_ROLE:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_LOAD_ROLE_SUCCESS:
			return { ...state, loading: false, role: action.payload, optionRole: action.payloadlist }
		case ROLE_MANAGEMENT_DELETE_ROLE:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_UPDATE_ROLE:
			return { ...state, loading: true }

		case ROLE_MANAGEMENT_LOAD_ROLELIST:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_LOAD_ROLELIST_SUCCESS:
			return { ...state, loading: false, rolelist: action.payload }
		case ROLE_MANAGEMENT_CREATE_ROLELIST:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_UPDATE_ROLELIST:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_DELETE_ROLELIST:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_DELETE_ROLELIST_USER:
			return { ...state, loading: true }

		case ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT:
			return { ...state, loading: true }
		case ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT_SUCCESS:
			return { ...state, loading: false, rolelistObject: action.payload }

		case LOGOUT_USER:
			return {
				...state,
				optioinNationality: [],
				roleCategory: [],
				optionRoleCategory: [],

				position: [],
				optionPosition: [],

				user: [],
				optionUser: [],
				resetUser: false,

				role: [],
				optionRole: [],

				permission: [],
				rolelist: [],
				rolelistObject: {},
			};


		default: return { ...state };
	}
}