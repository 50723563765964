// import { defaultLocale, localeOptions } from '../../constants/defaultValues'

import {

	LOGOUT_USER,
	COMPANY_DETAIL_LOAD_YACHT,
	COMPANY_DETAIL_LOAD_YACHT_SUCCESS,
	COMPANY_DETAIL_LOAD_COMPANY_INFORMATION,
	COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS,
	COMPANY_DETAIL_UPDATE_COMPANY_PROFILE,
	COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS,

} from '../actions';

const INIT_STATE = {

	CompanyLoading: false,
	dataYacht: null,
	dataCompanyInformation: null,

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		// case CHANGE_LOCALE:
		// 	return { ...state, locale: action.payload };
		// case SETTING_ERROR_MESSAGE:
		// 	return { ...state, loading: false, msgError: action.payload, alrtError: true };
		// case CLOSE_SETTING_ERROR_MESSAGE:
		// 	return { ...state, loading: false, msgError: '', alrtError: false }

		case COMPANY_DETAIL_LOAD_YACHT:
			return { ...state, CompanyLoading: true, }
		case COMPANY_DETAIL_LOAD_YACHT_SUCCESS:
			return { ...state, CompanyLoading: false, dataYacht: action.payload }
		case COMPANY_DETAIL_LOAD_COMPANY_INFORMATION:
			return { ...state, CompanyLoading: true, }
		case COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS:
			return { ...state, CompanyLoading: false, dataCompanyInformation: action.payload }
		case COMPANY_DETAIL_UPDATE_COMPANY_PROFILE:
			return { ...state, CompanyLoading: true }
		case COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS:
			return { ...state, CompanyLoading: false }
		case LOGOUT_USER:
			return {
				...state,
				CompanyLoading: false,
				dataYacht: null,
				dataCompanyInformation: null,
			};

		default: return { ...state };
	}
}