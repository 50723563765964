import Provider from "./Provider-Api";
import CheckApi from "./check-api";


export const yachtInformationGetYachtinfo = async (data, token) => {
    CheckApi('getYachtinfo', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtinfo",
        data,
        token
    });
    CheckApi('dataRes-getYachtinfo', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtTypes = async (token) => {
    CheckApi('getYachtTypes', token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtTypes",
        token
    });
    CheckApi('dataRes-getYachtTypes', dataRes)
    return dataRes;
};

export const yachtInformationGetPort = async (token) => {
    CheckApi('getPort', token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getPort",
        token
    });
    CheckApi('dataRes-getPort', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtGallery = async (data,) => {
    CheckApi('getYachtGallery', data)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtGallery",
        data,
        token: false
    });
    CheckApi('dataRes-getYachtGallery', dataRes)
    return dataRes;
};

export const yachtInformationUpdateChangeStatusYachtInfo = async (data, token) => {
    CheckApi('updateChangeStatusYachtInfo', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateChangeStatusYachtInfo",
        data,
        token
    });
    CheckApi('dataRes-updateChangeStatusYachtInfo', dataRes)
    return dataRes;
};

export const yachtInformationCreateYachtinfo = async (data, token) => {
    CheckApi('createYachtinfo', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/createYachtinfo",
        data,
        token
    });
    CheckApi('dataRes-createYachtinfo', dataRes)
    return dataRes;
};

// export const yachtInformationCreateYachtinfo = async (data, token) => {
//     CheckApi('createYachtinfo', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/yachtInformation/createYachtinfo",
//         data,
//         token
//     });
//     CheckApi('dataRes-createYachtinfo', dataRes)
//     return dataRes;
// };

export const yachtInformationUpdateYachtinfo = async (data, token) => {
    CheckApi('updateYachtinfo', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateYachtinfo",
        data,
        token
    });
    CheckApi('dataRes-updateYachtinfo', dataRes)
    return dataRes;
};

export const yachtInformationDeleteYachtinfo = async (item, token) => {
    CheckApi('deleteYachtinfo', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/deleteYachtinfo",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteYachtinfo', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtInfoAmenities = async (data, token) => {
    CheckApi('getYachtInfoAmenities', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtInfoAmenities",
        data,
        token
    });
    CheckApi('dataRes-getYachtInfoAmenities', dataRes)
    return dataRes;
};

export const yachtInformationAddYachtInfoAmenities = async (data, token) => {
    CheckApi('addYachtInfoAmenities', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/addYachtInfoAmenities",
        data,
        token
    });
    CheckApi('dataRes-addYachtInfoAmenities', dataRes)
    return dataRes;
};

export const yachtInformationUpdateYachtInfoAmenities = async (data, token) => {
    CheckApi('updateYachtInfoAmenities', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateYachtInfoAmenities",
        data,
        token
    });
    CheckApi('dataRes-updateYachtInfoAmenities', dataRes)
    return dataRes;
};

export const yachtInformationDeleteYachtInfoAmenities = async (item, token) => {
    CheckApi('deleteYachtInfoAmenities', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/deleteYachtInfoAmenities",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteYachtInfoAmenities', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtInfoWaterToy = async (data, token) => {
    CheckApi('getYachtInfoWaterToy', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtInfoWaterToy",
        data,
        token
    });
    CheckApi('dataRes-getYachtInfoWaterToy', dataRes)
    return dataRes;
};

export const yachtInformationAddYachtInfoWaterToy = async (data, token) => {
    CheckApi('addYachtInfoWaterToy', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/addYachtInfoWaterToy",
        data,
        token
    });
    CheckApi('dataRes-addYachtInfoWaterToy', dataRes)
    return dataRes;
};

export const yachtInformationUpdateYachtInfoWaterToy = async (data, token) => {
    CheckApi('updateYachtInfoWaterToy', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateYachtInfoWaterToy",
        data,
        token
    });
    CheckApi('dataRes-updateYachtInfoWaterToy', dataRes)
    return dataRes;
};

export const yachtInformationDeleteYachtInfoWaterToy = async (item, token) => {
    CheckApi('deleteYachtInfoWaterToy', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/deleteYachtInfoWaterToy",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteYachtInfoWaterToy', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtInfoAddonAndSurcharge = async (data, token) => {
    CheckApi('getYachtInfoAddonAndSurcharge', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtInfoAddonAndSurcharge",
        data,
        token
    });
    CheckApi('dataRes-getYachtInfoAddonAndSurcharge', dataRes)
    return dataRes;
};

export const yachtInformationAddYachtInfoAddonAndSurcharge = async (data, token) => {
    CheckApi('addYachtInfoAddonAndSurcharge', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/addYachtInfoAddonAndSurcharge",
        data,
        token
    });
    CheckApi('dataRes-addYachtInfoAddonAndSurcharge', dataRes)
    return dataRes;
};

export const yachtInformationUpdateYachtInfoAddonAndSurcharge = async (data, token) => {
    CheckApi('updateYachtInfoAddonAndSurcharge', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateYachtInfoAddonAndSurcharge",
        data,
        token
    });
    CheckApi('dataRes-updateYachtInfoAddonAndSurcharge', dataRes)
    return dataRes;
};

export const yachtInformationDeleteYachtInfoAddonAndSurcharge = async (item, token) => {
    CheckApi('deleteYachtInfoAddonAndSurcharge', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/deleteYachtInfoAddonAndSurcharge",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteYachtInfoAddonAndSurcharge', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtInfoRatePlanList = async (data, token) => {
    CheckApi('getYachtInfoRatePlanList', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtInfoRatePlanList",
        data,
        token
    });
    CheckApi('dataRes-getYachtInfoRatePlanList', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtInfoSellingPriceRatePlanList = async (data, token) => {
    CheckApi('getYachtInfoSellingPriceRatePlanList', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtInfoSellingPriceRatePlanList",
        data,
        token
    });
    CheckApi('dataRes-getYachtInfoSellingPriceRatePlanList', dataRes)
    return dataRes;
};

export const yachtInformationAddYachtInfoRatePlanList = async (data, token) => {
    CheckApi('addYachtInfoRatePlanList', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/addYachtInfoRatePlanList",
        data,
        token
    });
    CheckApi('dataRes-addYachtInfoRatePlanList', dataRes)
    return dataRes;
};

export const yachtInformationUpdateYachtInfoRatePlanList = async (data, token) => {
    CheckApi('updateYachtInfoRatePlanList', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateYachtInfoRatePlanList",
        data,
        token
    });
    CheckApi('dataRes-updateYachtInfoRatePlanList', dataRes)
    return dataRes;
};

export const yachtInformationDeleteYachtInfoRatePlanList = async (item, token) => {
    CheckApi('deleteYachtInfoRatePlanList', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/deleteYachtInfoRatePlanList",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteYachtInfoRatePlanList', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtInfoGallery = async (data, token) => {
    CheckApi('getYachtInfoGallery', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtInfoGallery",
        data,
        token
    });
    CheckApi('dataRes-getYachtInfoGallery', dataRes)
    return dataRes;
};

export const yachtInformationAddYachtInfoGallery = async (data, token) => {
    CheckApi('addYachtInfoGallery', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/addYachtInfoGallery",
        data,
        token
    });
    CheckApi('dataRes-addYachtInfoGallery', dataRes)
    return dataRes;
};

export const yachtInformationUpdateYachtInfoGallery = async (data, token) => {
    CheckApi('updateYachtInfoGallery', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateYachtInfoGallery",
        data,
        token
    });
    CheckApi('dataRes-updateYachtInfoGallery', dataRes)
    return dataRes;
};

export const yachtInformationDeleteYachtInfoGallery = async (item, token) => {
    CheckApi('deleteYachtInfoGallery', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/deleteYachtInfoGallery",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteYachtInfoGallery', dataRes)
    return dataRes;
};

export const yachtInformationGetYachtInfoDestinationPackage = async (data, token) => {
    CheckApi('getYachtInfoDestinationPackage', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getYachtInfoDestinationPackage",
        data,
        token
    });
    CheckApi('dataRes-getYachtInfoDestinationPackage', dataRes)
    return dataRes;
};

export const yachtInformationGetAllYachtInfoDestinationPackagebyYacht = async (data, token) => {
    CheckApi('getAllYachtInfoDestinationPackagebyYacht', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/getAllYachtInfoDestinationPackagebyYacht",
        data,
        token
    });
    CheckApi('dataRes-getAllYachtInfoDestinationPackagebyYacht', dataRes)
    return dataRes;
};

export const yachtInformationAddYachtInfoDestinationPackage = async (data, token) => {
    CheckApi('addYachtInfoDestinationPackage', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/addYachtInfoDestinationPackage",
        data,
        token
    });
    CheckApi('dataRes-addYachtInfoDestinationPackage', dataRes)
    return dataRes;
};

export const yachtInformationUpdateYachtInfoDestinationPackage = async (data, token) => {
    CheckApi('updateYachtInfoDestinationPackage', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/updateYachtInfoDestinationPackage",
        data,
        token
    });
    CheckApi('dataRes-updateYachtInfoDestinationPackage', dataRes)
    return dataRes;
};

export const yachtInformationDeleteYachtInfoDestinationPackage = async (item, token) => {
    CheckApi('deleteYachtInfoDestinationPackage', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/yachtInformation/deleteYachtInfoDestinationPackage",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteYachtInfoDestinationPackage', dataRes)
    return dataRes;
};