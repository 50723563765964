import { all, fork, put, takeEvery } from "redux-saga/effects";

import {

    // USER_MANAGEMENT_LOAD_NATIONALITY,
    USER_MANAGEMENT_ADD_NAME_ROLECATEGORY,
    USER_MANAGEMENT_LOAD_ROLECATEGORY,
    USER_MANAGEMENT_DELETE_ROLECATEGORY,
    USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY,
    CREATE_USER,
    // CREATE_USER_SUCCESS,
    LOAD_USER,
    // LOAD_USER_SUCCESS,
    DELETE_USER,
    // DELETE_USER_SUCCESS,
    USER_MANAGEMENT_UPDATE_USER,
    USER_MANAGEMENT_CREATE_USER_PASS,
    USER_MANAGEMENT_RESET_USER_PASS,

    ROLE_MANAGEMENT_CREATE_ROLE,
    ROLE_MANAGEMENT_LOAD_ROLE,
    ROLE_MANAGEMENT_DELETE_ROLE,
    ROLE_MANAGEMENT_UPDATE_ROLE,
    ROLE_MANAGEMENT_CREATE_ROLELIST,
    ROLE_MANAGEMENT_LOAD_ROLELIST,
    // ROLE_MANAGEMENT_LOAD_ROLELIST_SUCCESS,
    ROLE_MANAGEMENT_UPDATE_ROLELIST,
    ROLE_MANAGEMENT_DELETE_ROLELIST,
    ROLE_MANAGEMENT_DELETE_ROLELIST_USER,
    ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT

} from "../actions";

import {
    userGetRoleCategory,
    userAddRoleCategory,
    userDeleteRoleCategory,
    userUpdateRoleCategory,
    userGetUser,
    userCreateUser,
    userDeleteUser,
    userUpdateUser,
    userCreateUserPassword,
    userResetPassword,
    userGetRole,
    userAddRole,
    userDeleteRole,
    userUpdateRole,
    userGetRolelist,
    userAddRolelist,
    userDeleteRolelist,
    userUpdateRolelist,
    userDeleteUserRolelist,

} from "../../helpers/user-api";

// import {
//     settingErrorMessage,
//     getDepartmentSuccess,
//     getCountrySuccess,
//     // addDepartmentSuccess,
//     getDepartment,
//     getPosition,
//     getPositionSuccess,
//     getUser,
//     getUserSuccess,
//     getRole,
//     getRoleSuccess,
//     getPermission,
//     getPermissionSuccess,
// } from "./actions";

import {
    // settingErrorMessage,
    getRoleCategory,
    getRoleCategorySuccess,
    getUser,
    getUserSuccess,
    resetPasswordSuccess,
    getRole,
    getRoleSuccess,
    getRolelist,
    getRolelistSuccess,
    getRoleObjectlist,
    getRolelistObjectSuccess,
    // getNationalitySuccess,
} from "./actions";

import { NotificationManager } from '../../components/common/react-notifications';

const PullNotificationManager = async (text) => {
    // eslint-disable-next-line no-return-await
    await NotificationManager.warning(text, 'Error', 3000, null, null, '');
}

//  RoleCategory
function* userManagementLoadRoleCategory({ payload, token }) {
    try {
        // console.log('userManagementLoadRoleCategory', payload, token)
        const dataRes = yield userGetRoleCategory(payload, token);
        if (dataRes.status === 'success') {
            let list = [];
            list = dataRes.data.map(item => (
                { label: item.name_role_category, value: item.id_role_category }
            ));
            yield put(getRoleCategorySuccess(dataRes.data, list));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* userManagementAddRoleCategory({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield userAddRoleCategory(payload, token);
        if (dataRes.status === 'success') {
            yield put(getRoleCategory({ yacht_id: payload.yacht_id }, token));
        } else
            if
                (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }

            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* userManagementDeleteRoleCategory({ payload, token }) {
    const { items } = payload;
    try {
        const dataRes = yield userDeleteRoleCategory(items, token);
        if (dataRes.status === 'success') yield put(getRoleCategory({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else if (dataRes.err.code === "ER_ROW_IS_REFERENCED_2") {
                yield PullNotificationManager("RoleCategory is used.");
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    }
    catch (error) {
        console.log('error', error)
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* userManagementUpdateRoleCategory({ payload, token }) {
    const { nameRoleCategory, id } = payload;
    try {
        const dataRes = yield userUpdateRoleCategory(id, nameRoleCategory, token);
        if (dataRes.status === 'success') {
            yield put(getRoleCategory({ yacht_id: payload.yacht_id }, token));
            // yield put(getPosition({ idHotel }, token));
            // yield put(getUser());
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    }
    catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
//  User
function* createUser({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield userCreateUser(payload, token);
        if (dataRes.status === 'success') {
            yield put(getUser({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* getdataUser({ payload, token }) {
    try {
        const dataRes = yield userGetUser(payload, token);
        if (dataRes.status === 'success') {
            let list = [];
            list = dataRes.data.map(item => (
                {
                    // label: `${item.first_name} ${item.last_name}`,
                    label: `${item.first_name}`,
                    value: item.id_user,
                    // id_department: item.id_department,
                    // id_position: item.id_position
                }
            ));
            yield put(getUserSuccess(dataRes.data, list));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* deleteDataUser({ payload, token }) {
    // const { items } = payload;
    try {
        const dataRes = yield userDeleteUser(payload, token);
        if (dataRes.status === 'success') yield put(getUser({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {

                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* updateUser({ payload, token }) {
    // const { yacht_id } = payload;
    try {
        const dataRes = yield userUpdateUser(payload, token);
        if (dataRes.status === 'success') {
            yield put(getUser({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* createUserPassword({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        // let token = localStorage.getItem('token')
        const dataRes = yield userCreateUserPassword(payload, token);
        if (dataRes.status === 'success') {
            yield put(getUser({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data username is duplicated. Please change the data username.");
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* resetPassword({ payload, token }) {
    // const { yacht_id } = payload;
    try {
        const dataRes = yield userResetPassword(payload, token);
        if (dataRes.status === 'success') {
            yield put(getUser({ yacht_id: payload.yacht_id }, token));
            // const resetStatus = dataRes.status.toString()
            // console.log('dataRes.status', dataRes.status)
            yield put(resetPasswordSuccess(true));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {

                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* userManagementAddRole({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield userAddRole(payload, token);
        if (dataRes.status === 'success') {
            yield put(getRole({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.");
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* userManagementLoadRole({ payload, token }) {
    try {
        const dataRes = yield userGetRole(payload, token);
        if (dataRes.status === 'success') {
            let list = [];
            list = dataRes.data.map(item => (
                {
                    label: item.name_role,
                    value: item.id_role
                }
            ));
            yield put(getRoleSuccess(dataRes.data, list));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* userManagementDeleteRole({ payload, token }) {
    const { items } = payload;
    try {
        const dataRes = yield userDeleteRole(items, token);
        if (dataRes.status === 'success') yield put(getRole({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else if (dataRes.err.code === "ER_ROW_IS_REFERENCED_2") {
                yield PullNotificationManager("Role is used.");
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        console.log('error', error)
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* userManagementUpdateRole({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield userUpdateRole(payload, token);
        if (dataRes.status === 'success') {
            yield put(getRole({ yacht_id: payload.yacht_id }, token));
            // yield put(getRolelist(token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.");
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* userManagementGetRolelist({ payload, token }) {
    try {
        const dataRes = yield userGetRolelist(payload, token);
        if (dataRes.status === 'success') {
            yield put(getRolelistSuccess(dataRes.data));
            yield put(getRoleObjectlist(dataRes.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false)
                yield PullNotificationManager("TIME OUT! Please Login again.")
            // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            else
                yield PullNotificationManager(`${dataRes.msg}`);
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* userManagementAddRolelist({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield userAddRolelist(payload, token);
        if (dataRes.status === 'success') {
            yield put(getRolelist({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false)
                yield PullNotificationManager("TIME OUT! Please Login again.")
            // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            else
                yield PullNotificationManager(`${dataRes.msg}`);
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* userManagementUpdateRolelist({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield userUpdateRolelist(payload, token);
        if (dataRes.status === 'success') {
            yield put(getRolelist({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false)
                yield PullNotificationManager("TIME OUT! Please Login again.")
            // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            else
                yield PullNotificationManager(`${dataRes.msg}`);

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}
function* userManagementDeleteRolelist({ payload, token }) {
    const { items } = payload;
    try {
        const dataRes = yield userDeleteRolelist(items, token);
        if (dataRes.status === 'success') yield put(getRolelist({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else if (dataRes.err.code === "ER_ROW_IS_REFERENCED_2") {
                yield PullNotificationManager("Rolelist is used.");
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        console.log('error', error)
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* userManagementDeleteRolelistUser({ payload, token }) {
    try {
        const { items } = payload;
        const dataRes = yield userDeleteUserRolelist(items, token);
        if (dataRes.status === 'success') yield put(getRolelist({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else if (dataRes.err.code === "ER_ROW_IS_REFERENCED_2") {
                yield PullNotificationManager("Rolelist is used.");
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        console.log('error', error)
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}


function* userManagementGetRolelistObject({ payload }) {
    /* eslint-disable no-param-reassign */
    const groupBy = function group(xs, key) {
        return xs.reduce(function groupReturn(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    /* eslint-enable no-param-reassign */
    try {
        const data = yield groupBy(payload, "id_role")
        if (data) {
            yield put(getRolelistObjectSuccess(data));
        } else {
            yield PullNotificationManager("OMG! ERROR");
        }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

// RoleCategory
export function* watchAddRoleCategory() {
    yield takeEvery(
        USER_MANAGEMENT_ADD_NAME_ROLECATEGORY,
        userManagementAddRoleCategory
    );
}
export function* watchLoadRoleCategory() {
    yield takeEvery(
        USER_MANAGEMENT_LOAD_ROLECATEGORY,
        userManagementLoadRoleCategory
    );
}
export function* watchDeleteRoleCategory() {
    yield takeEvery(
        USER_MANAGEMENT_DELETE_ROLECATEGORY,
        userManagementDeleteRoleCategory
    );
}
export function* watchUpdateRoleCategory() {
    yield takeEvery(
        USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY,
        userManagementUpdateRoleCategory
    );
}

// User
export function* watchCreateUser() {
    yield takeEvery(
        CREATE_USER,
        createUser
    );
}
export function* watchGetUser() {
    yield takeEvery(
        LOAD_USER,
        getdataUser
    );
}
export function* watchDeleteUser() {
    yield takeEvery(
        DELETE_USER,
        deleteDataUser
    );
}
export function* watchUpdateUser() {
    yield takeEvery(
        USER_MANAGEMENT_UPDATE_USER,
        updateUser
    );
}
export function* watchCreateUsernameAndPassword() {
    yield takeEvery(
        USER_MANAGEMENT_CREATE_USER_PASS,
        createUserPassword
    );
}
export function* watchResetPassword() {
    yield takeEvery(
        USER_MANAGEMENT_RESET_USER_PASS,
        resetPassword
    );
}

// Role
export function* watchCreateRole() {
    yield takeEvery(
        ROLE_MANAGEMENT_CREATE_ROLE,
        userManagementAddRole
    );
}
export function* watchGetRole() {
    yield takeEvery(
        ROLE_MANAGEMENT_LOAD_ROLE,
        userManagementLoadRole
    );
}
export function* watchDeleteRole() {
    yield takeEvery(
        ROLE_MANAGEMENT_DELETE_ROLE,
        userManagementDeleteRole
    );
}
export function* watchUpdateRole() {
    yield takeEvery(
        ROLE_MANAGEMENT_UPDATE_ROLE,
        userManagementUpdateRole
    );
}
export function* watchAddRolelist() {
    yield takeEvery(
        ROLE_MANAGEMENT_CREATE_ROLELIST,
        userManagementAddRolelist
    );
}
export function* watchGetRolelist() {
    yield takeEvery(
        ROLE_MANAGEMENT_LOAD_ROLELIST,
        userManagementGetRolelist
    );
}
export function* watchUpdateRolelist() {
    yield takeEvery(
        ROLE_MANAGEMENT_UPDATE_ROLELIST,
        userManagementUpdateRolelist
    );
}
export function* watchDeleteRolelist() {
    yield takeEvery(
        ROLE_MANAGEMENT_DELETE_ROLELIST,
        userManagementDeleteRolelist
    );
}
export function* watchDeleteRolelistUser() {
    yield takeEvery(
        ROLE_MANAGEMENT_DELETE_ROLELIST_USER,
        userManagementDeleteRolelistUser
    );
}
export function* watchGetRolelistObject() {
    yield takeEvery(
        ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT,
        userManagementGetRolelistObject
    );
}

export default function* rootSaga() {
    yield all([
        //  RoleCategory
        fork(watchAddRoleCategory),
        fork(watchLoadRoleCategory),
        fork(watchDeleteRoleCategory),
        fork(watchUpdateRoleCategory),
        //   User
        fork(watchCreateUser),
        fork(watchGetUser),
        fork(watchDeleteUser),
        fork(watchUpdateUser),
        fork(watchCreateUsernameAndPassword),
        fork(watchResetPassword),
        // LoadCountry
        // fork(watchLoadNationality),
        // Role
        fork(watchCreateRole),
        fork(watchGetRole),
        fork(watchDeleteRole),
        fork(watchUpdateRole),

        fork(watchAddRolelist),
        fork(watchGetRolelist),
        fork(watchUpdateRolelist),
        fork(watchDeleteRolelist),
        fork(watchDeleteRolelistUser),
        fork(watchGetRolelistObject),
    ])
}