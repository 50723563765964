// import { defaultLocale, localeOptions } from '../../constants/defaultValues'

import {

	LOGOUT_USER,
	BOOKING_LOAD_BOOKING_LIST,
	BOOKING_LOAD_BOOKING_LIST_SUCCESS,
	BOOKING_LOAD_ALL_PAYMENT_BOOKING_LIST_SUCCESS,

} from '../actions';

const INIT_STATE = {

	BookingLoading: false,
	bookinglist: null,
	allPaymentBookinglist: null,
	// dataCompanyInformation: null,

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case BOOKING_LOAD_BOOKING_LIST:
			return { ...state, BookingLoading: true, }
		case BOOKING_LOAD_BOOKING_LIST_SUCCESS:
			return { ...state, BookingLoading: false, bookinglist: action.payload }
		case BOOKING_LOAD_ALL_PAYMENT_BOOKING_LIST_SUCCESS:
			return { ...state, BookingLoading: false, allPaymentBookinglist: action.payload }
		// case COMPANY_DETAIL_LOAD_COMPANY_INFORMATION:
		// 	return { ...state, CompanyLoading: true, }
		// case COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS:
		// 	return { ...state, CompanyLoading: false, dataCompanyInformation: action.payload }
		// case COMPANY_DETAIL_UPDATE_COMPANY_PROFILE:
		// 	return { ...state, CompanyLoading: true }
		// case COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS:
		// 	return { ...state, CompanyLoading: false }
		case LOGOUT_USER:
			return {
				...state,
				BookingLoading: false,
				bookinglist: null,
				allPaymentBookinglist: null,
				// dataCompanyInformation: null,
			};

		default: return { ...state };
	}
}