import { all, fork, put, takeEvery } from "redux-saga/effects";

import {

    COMPANY_DETAIL_LOAD_YACHT,
    // COMPANY_DETAIL_LOAD_YACHT_SUCCESS,
    COMPANY_DETAIL_LOAD_COMPANY_INFORMATION,
    // COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS,
    COMPANY_DETAIL_UPDATE_COMPANY_PROFILE,
    // COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS,

} from "../actions";

import {

    companyDetailGetYacht,
    companyDetailGetCompanyInformation,
    companyDetailUpdateCompanyProfile,

} from "../../helpers/companyDetail-api";

import { NotificationManager } from '../../components/common/react-notifications';


import {

    getYacht,
    getYachtSuccess,
    getCompanyInformation,
    getCompanyInformationSuccess,
    // updateCompanyProfile,
    updateCompanyProfileSuccess,

} from "./actions";


//  Company Profile
function* companyDetailLoadYacht({ payload, token }) {
    try {
        console.log('companyDetailLoadYacht', payload, token)
        const dataRes = yield companyDetailGetYacht(payload, token);
        if (dataRes.status === 'success') {
            yield put(getYachtSuccess(dataRes.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield NotificationManager.warning("TIME OUT! Please Login again.", 'Error', 3000, null, null, '');
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* companyDetailLoadCompanyInformation({ payload, token }) {
    try {
        // console.log('companyDetailLoadRoleYacht', payload, token)
        const dataRes = yield companyDetailGetCompanyInformation(payload, token);
        if (dataRes.status === 'success') {
            yield put(getCompanyInformationSuccess(dataRes.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield NotificationManager.warning("TIME OUT! Please Login again.", 'Error', 3000, null, null, '');
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* companyDetailUpdateUser({ payload, token }) {
    try {
        const dataRes = yield companyDetailUpdateCompanyProfile(payload, token);
        if (dataRes.status === 'success') {
            yield put(getYacht({ yacht_id: payload.yacht_id }, token));
            yield put(getCompanyInformation({ yacht_id: payload.yacht_id }, token));
            yield put(updateCompanyProfileSuccess())
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield NotificationManager.warning("Oops! The data name is duplicated. Please change the data name.", 'Error', 3000, null, null, '');
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield NotificationManager.warning("TIME OUT! Please Login again.", 'Error', 3000, null, null, '');
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield NotificationManager.warning(dataRes.msg, 'Error', 3000, null, null, '');
                // yield put(settingErrorMessage(`${dataRes.msg}`));
            }

    } catch (error) {
        yield NotificationManager.warning("OMG! Please wait about 30s and refresh your browser again.", 'Error', 3000, null, null, '');
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}


// Company Profile
export function* watchLoadYacht() {
    yield takeEvery(
        COMPANY_DETAIL_LOAD_YACHT,
        companyDetailLoadYacht
    );
}
export function* watchLoadCompanyInformation() {
    yield takeEvery(
        COMPANY_DETAIL_LOAD_COMPANY_INFORMATION,
        companyDetailLoadCompanyInformation
    );
}
export function* watchUpdateUser() {
    yield takeEvery(
        COMPANY_DETAIL_UPDATE_COMPANY_PROFILE,
        companyDetailUpdateUser
    );
}

export default function* rootSaga() {
    yield all([
        //  Company Profile
        fork(watchLoadYacht),
        fork(watchLoadCompanyInformation),
        fork(watchUpdateUser),
    ])
}