// eslint-disable-next-line import/no-cycle
import {

    YACHT_INFOMATION_LOAD_YACHTINFO,
    YACHT_INFOMATION_LOAD_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_CREATE_YACHTINFO,
    YACHT_INFOMATION_CREATE_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_UPDATE_YACHTINFO,
    YACHT_INFOMATION_UPDATE_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_DELETE_YACHTINFO,
    YACHT_INFOMATION_DELETE_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS,
    YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS_SUCCESS,

} from '../actions';

// // -------------------- getYachtinfo --------------------- //
export const getYachtinfo = (data, token) => ({
    type: YACHT_INFOMATION_LOAD_YACHTINFO,
    payload: data,
    token
});
export const getYachtinfoSuccess = (data) => ({
    type: YACHT_INFOMATION_LOAD_YACHTINFO_SUCCESS,
    payload: data
});

// // -------------------- createYachtinfo --------------------- //
export const createYachtinfo = (data, token) => ({
    type: YACHT_INFOMATION_CREATE_YACHTINFO,
    payload: data,
    token
});
export const createYachtinfoSuccess = () => ({
    type: YACHT_INFOMATION_CREATE_YACHTINFO_SUCCESS,
});

// // -------------------- updateYachtinfo --------------------- //
export const updateYachtinfo = (data, token) => ({
    type: YACHT_INFOMATION_UPDATE_YACHTINFO,
    payload: data,
    token
});
export const updateYachtinfoSuccess = () => ({
    type: YACHT_INFOMATION_UPDATE_YACHTINFO_SUCCESS,
});

// // -------------------- deleteYachtinfo --------------------- //
export const deleteYachtinfo = (data, token) => ({
    type: YACHT_INFOMATION_DELETE_YACHTINFO,
    payload: data,
    token
});
export const deleteYachtinfoSuccess = () => ({
    type: YACHT_INFOMATION_DELETE_YACHTINFO_SUCCESS,
});

// // -------------------- updateYachtinfo --------------------- //
export const updateChangeStatusYachtInfo = (data, token) => ({
    type: YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS,
    payload: data,
    token
});
export const updateChangeStatusYachtInfoSuccess = () => ({
    type: YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS_SUCCESS,
});
