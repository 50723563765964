import Provider from "./Provider-Api";
import CheckApi from "./check-api";


export const bookingGetBooking = async (data, token) => {
    CheckApi('getBooking', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getBooking",
        data,
        token
    });
    CheckApi('dataRes-getBooking', dataRes)
    return dataRes;
};

export const bookingAddBooking = async (data, token) => {
    CheckApi('addBooking', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/addBooking",
        data,
        token
    });
    CheckApi('dataRes-addBooking', dataRes)
    return dataRes;
};

export const bookingUpdateBooking = async (data, token) => {
    CheckApi('updateBooking', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updateBooking",
        data,
        token
    });
    CheckApi('dataRes-updateBooking', dataRes)
    return dataRes;
};

export const bookingUpdateBookingPaymentANDBill = async (data, token) => {
    CheckApi('updateBookingPaymentANDBill', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updateBookingPaymentANDBill",
        data,
        token
    });
    CheckApi('dataRes-updateBookingPaymentANDBill', dataRes)
    return dataRes;
};

export const bookingUpdateBookingStatus = async (data, token) => {
    CheckApi('updateBookingStatus', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updateBookingStatus",
        data,
        token
    });
    CheckApi('dataRes-updateBookingStatus', dataRes)
    return dataRes;
};

export const bookingGetBookCustomerdetail = async (data, token) => {
    CheckApi('getBookCustomerdetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getBookCustomerdetail",
        data,
        token
    });
    CheckApi('dataRes-getBookCustomerdetail', dataRes)
    return dataRes;
};

export const bookingAddBookCustomerdetail = async (data, token) => {
    CheckApi('addBookCustomerdetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/addBookCustomerdetail",
        data,
        token
    });
    CheckApi('dataRes-addBookCustomerdetail', dataRes)
    return dataRes;
};

export const bookingUpdateBookCustomerdetail = async (data, token) => {
    CheckApi('updateBookCustomerdetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updateBookCustomerdetail",
        data,
        token
    });
    CheckApi('dataRes-updateBookCustomerdetail', dataRes)
    return dataRes;
};

export const bookingDeleteBookCustomerdetail = async (item, token) => {
    CheckApi('deleteBookCustomerdetail', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/deleteBookCustomerdetail",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteBookCustomerdetail', dataRes)
    return dataRes;
};

export const bookingGetBookYachtdetail = async (data, token) => {
    CheckApi('getBookYachtdetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getBookYachtdetail",
        data,
        token
    });
    CheckApi('dataRes-getBookYachtdetail', dataRes)
    return dataRes;
};

export const bookingAddBookYachtdetail = async (data, token) => {
    CheckApi('addBookYachtdetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/addBookYachtdetail",
        data,
        token
    });
    CheckApi('dataRes-addBookYachtdetail', dataRes)
    return dataRes;
};

export const bookingUpdateBookYachtdetail = async (data, token) => {
    CheckApi('updateBookYachtdetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updateBookYachtdetail",
        data,
        token
    });
    CheckApi('dataRes-updateBookYachtdetail', dataRes)
    return dataRes;
};

export const bookingDeleteBookYachtdetail = async (item, token) => {
    CheckApi('deleteBookYachtdetail', item)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/deleteBookYachtdetail",
        data: {
            item
        },
        token
    });
    CheckApi('dataRes-deleteBookYachtdetail', dataRes)
    return dataRes;
};

export const bookingGetPaymentBooking = async (data, token) => {
    CheckApi('getPaymentBooking', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getPaymentBooking",
        data,
        token
    });
    CheckApi('dataRes-getPaymentBooking', dataRes)
    return dataRes;
};

export const bookingGetPaymentBookingbyYacht = async (data, token) => {
    CheckApi('getPaymentBookingbyYacht', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getPaymentBookingbyYacht",
        data,
        token
    });
    CheckApi('dataRes-getPaymentBookingbyYacht', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBooking = async (data, token) => {
    CheckApi('updatePaymentBooking', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBooking",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBooking', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBookingPromotion = async (data, token) => {
    CheckApi('updatePaymentBookingPromotion', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBookingPromotion",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBookingPromotion', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBookingWithBookingStatus = async (data, token) => {
    CheckApi('updatePaymentBookingWithBookingStatus', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBookingWithBookingStatus",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBookingWithBookingStatus', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBookingEditPromotion = async (data, token) => {
    CheckApi('updatePaymentBookingEditPromotion', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBookingEditPromotion",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBookingEditPromotion', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBookingRefund = async (data, token) => {
    CheckApi('updatePaymentBookingRefund', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBookingRefund",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBookingRefund', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBookingAddon = async (data, token) => {
    CheckApi('updatePaymentBookingAddon', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBookingAddon",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBookingAddon', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBookingBuyerDetail = async (data, token) => {
    CheckApi('updatePaymentBookingBuyerDetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBookingBuyerDetail",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBookingBuyerDetail', dataRes)
    return dataRes;
};

export const bookingGetPaymentBill = async (data, token) => {
    CheckApi('getPaymentBill', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getPaymentBill",
        data,
        token
    });
    CheckApi('dataRes-getPaymentBill', dataRes)
    return dataRes;
};

export const bookingAddPaymentBill = async (data, token) => {
    CheckApi('addPaymentBill', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/addPaymentBill",
        data,
        token
    });
    CheckApi('dataRes-addPaymentBill', dataRes)
    return dataRes;
};

export const bookingGetPaymentBuyerDetail = async (data, token) => {
    CheckApi('getPaymentBuyerDetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getPaymentBuyerDetail",
        data,
        token
    });
    CheckApi('dataRes-getPaymentBuyerDetail', dataRes)
    return dataRes;
};

export const bookingGetPaymentAllBuyerDetail = async (data, token) => {
    CheckApi('getPaymentAllBuyerDetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getPaymentAllBuyerDetail",
        data,
        token
    });
    CheckApi('dataRes-getPaymentAllBuyerDetail', dataRes)
    return dataRes;
};

export const bookingAddPaymentBuyerDetail = async (data, token) => {
    CheckApi('addPaymentBuyerDetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/addPaymentBuyerDetail",
        data,
        token
    });
    CheckApi('dataRes-addPaymentBuyerDetail', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBuyerDetail = async (data, token) => {
    CheckApi('updatePaymentBuyerDetail', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBuyerDetail",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBuyerDetail', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBookingWithEditBooking = async (data, token) => {
    CheckApi('updatePaymentBookingWithEditBooking', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBookingWithEditBooking",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBookingWithEditBooking', dataRes)
    return dataRes;
};

export const bookingUpdatePaymentBillWithEditBooking = async (data, token) => {
    CheckApi('updatePaymentBillWithEditBooking', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/updatePaymentBillWithEditBooking",
        data,
        token
    });
    CheckApi('dataRes-updatePaymentBillWithEditBooking', dataRes)
    return dataRes;
};

export const bookingGetPaymentDataBill = async (data, token) => {
    CheckApi('getPaymentDataBill', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getPaymentDataBill",
        data,
        token
    });
    CheckApi('dataRes-getPaymentDataBill', dataRes)
    return dataRes;
};

export const bookingAddPaymentDataBill = async (data, token) => {
    CheckApi('addPaymentDataBill', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/addPaymentDataBill",
        data,
        token
    });
    CheckApi('dataRes-addPaymentDataBill', dataRes)
    return dataRes;
};

export const bookingGetPaymentTableBill = async (data, token) => {
    CheckApi('getPaymentTableBill', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/getPaymentTableBill",
        data,
        token
    });
    CheckApi('dataRes-getPaymentTableBill', dataRes)
    return dataRes;
};

export const bookingAddPaymentTableBill = async (data, token) => {
    CheckApi('addPaymentTableBill', data, token)
    // const dataRes = await 
    const dataRes = await Provider({
        type: "POST",
        url: "/booking/addPaymentTableBill",
        data,
        token
    });
    CheckApi('dataRes-addPaymentTableBill', dataRes)
    return dataRes;
};

// export const bookingGetRefundDataBill = async (data, token) => {
//     CheckApi('getRefundDataBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/getRefundDataBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-getRefundDataBill', dataRes)
//     return dataRes;
// };

// export const bookingAddRefundDataBill = async (data, token) => {
//     CheckApi('addRefundDataBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/addRefundDataBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-addRefundDataBill', dataRes)
//     return dataRes;
// };

// export const bookingGetRefundTableBill = async (data, token) => {
//     CheckApi('getRefundTableBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/getRefundTableBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-getRefundTableBill', dataRes)
//     return dataRes;
// };

// export const bookingAddRefundTableBill = async (data, token) => {
//     CheckApi('addRefundTableBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/addRefundTableBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-addRefundTableBill', dataRes)
//     return dataRes;
// };

// export const bookingGetRefundBill = async (data, token) => {
//     CheckApi('getRefundBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/getRefundBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-getRefundBill', dataRes)
//     return dataRes;
// };

// export const bookingAddRefundBill = async (data, token) => {
//     CheckApi('addRefundBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/addRefundBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-addRefundBill', dataRes)
//     return dataRes;
// };

// export const bookingGetAddonBill = async (data, token) => {
//     CheckApi('getAddonBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/getAddonBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-getAddonBill', dataRes)
//     return dataRes;
// };

// export const bookingAddAddonBill = async (data, token) => {
//     CheckApi('addAddonBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/addAddonBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-addAddonBill', dataRes)
//     return dataRes;
// };

// export const bookingGetBookingAddonPlusDataBill = async (data, token) => {
//     CheckApi('getBookingAddonPlusDataBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/getBookingAddonPlusDataBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-getBookingAddonPlusDataBill', dataRes)
//     return dataRes;
// };

// export const bookingAddBookingAddonPlusDataBill = async (data, token) => {
//     CheckApi('addBookingAddonPlusDataBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/addBookingAddonPlusDataBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-addBookingAddonPlusDataBill', dataRes)
//     return dataRes;
// };

// export const bookingGetBookingAddonPlusTableBill = async (data, token) => {
//     CheckApi('getBookingAddonPlusTableBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/getBookingAddonPlusTableBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-getBookingAddonPlusTableBill', dataRes)
//     return dataRes;
// };

// export const bookingAddBookingAddonPlusTableBill = async (data, token) => {
//     CheckApi('addBookingAddonPlusTableBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/addBookingAddonPlusTableBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-addBookingAddonPlusTableBill', dataRes)
//     return dataRes;
// };

// export const bookingUpdateBookingAddonPlusTableBill = async (data, token) => {
//     CheckApi('updateBookingAddonPlusTableBill', data, token)
//     // const dataRes = await 
//     const dataRes = await Provider({
//         type: "POST",
//         url: "/booking/updateBookingAddonPlusTableBill",
//         data,
//         token
//     });
//     CheckApi('dataRes-updateBookingAddonPlusTableBill', dataRes)
//     return dataRes;
// };