// eslint-disable-next-line import/no-cycle
import {

    BOOKING_LOAD_BOOKING_LIST,
    BOOKING_LOAD_BOOKING_LIST_SUCCESS,
    BOOKING_LOAD_ALL_PAYMENT_BOOKING_LIST_SUCCESS,

} from '../actions';

// // -------------------- Booklist --------------------- //
export const getBooklist = (data, token) => ({
    type: BOOKING_LOAD_BOOKING_LIST,
    payload: data,
    token
});
export const getBooklistSuccess = (data) => ({
    type: BOOKING_LOAD_BOOKING_LIST_SUCCESS,
    payload: data
});

export const getAllPaymentBookingSuccess = (data) => ({
    type: BOOKING_LOAD_ALL_PAYMENT_BOOKING_LIST_SUCCESS,
    payload: data
});


// // // ------------------ Company Information ------------------- //
// export const getCompanyInformation = (data, token) => ({
//     type: COMPANY_DETAIL_LOAD_COMPANY_INFORMATION,
//     payload: data,
//     token
// });
// export const getCompanyInformationSuccess = (data) => ({
//     type: COMPANY_DETAIL_LOAD_COMPANY_INFORMATION_SUCCESS,
//     payload: data
// });
// // // ------------------ Update Company Profile ------------------- //
// export const updateCompanyProfile = (data, token) => ({
//     type: COMPANY_DETAIL_UPDATE_COMPANY_PROFILE,
//     payload: data,
//     token
// });
// export const updateCompanyProfileSuccess = () => ({
//     type: COMPANY_DETAIL_UPDATE_COMPANY_PROFILE_SUCCESS
// });




