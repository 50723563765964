import { all, fork, put, takeEvery } from "redux-saga/effects";

import {

    YACHT_INFOMATION_LOAD_YACHTINFO,
    // YACHT_INFOMATION_LOAD_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_CREATE_YACHTINFO,
    // YACHT_INFOMATION_CREATE_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_UPDATE_YACHTINFO,
    // YACHT_INFOMATION_UPDATE_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_DELETE_YACHTINFO,
    // YACHT_INFOMATION_DELETE_YACHTINFO_SUCCESS,
    YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS,
    // YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS_SUCCESS,


} from "../actions";

import {

    yachtInformationGetYachtinfo,
    yachtInformationCreateYachtinfo,
    yachtInformationUpdateYachtinfo,
    yachtInformationDeleteYachtinfo,
    yachtInformationUpdateChangeStatusYachtInfo,

} from "../../helpers/yachtInfo-api";

import { NotificationManager } from '../../components/common/react-notifications';


import {

    getYachtinfo,
    getYachtinfoSuccess,
    // createYachtinfo,
    // createYachtinfoSuccess,
    // updateYachtinfo,
    // updateYachtinfoSuccess,
    // deleteYachtinfo,
    // deleteYachtinfoSuccess,
    // updateChangeStatusYachtInfo,
    // updateChangeStatusYachtInfoSuccess

} from "./actions";

const PullNotificationManager = async (text) => {
    // eslint-disable-next-line no-return-await
    await NotificationManager.warning(text, 'Error', 3000, null, null, '');
}

//  Company Profile
function* yachtInformationLoadYachtinfo({ payload, token }) {
    try {
        const dataRes = yield yachtInformationGetYachtinfo(payload, token);
        if (dataRes.status === 'success') {
            yield put(getYachtinfoSuccess(dataRes.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* yachtInfoCreateYachtinfo({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield yachtInformationCreateYachtinfo(payload, token);
        if (dataRes.status === 'success') {
            yield put(getYachtinfo({ yacht_id: payload.yacht_id }, token));
        } else
            if
                (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }

            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* updateYachtinfo({ payload, token }) {
    try {
        const dataRes = yield yachtInformationUpdateYachtinfo(payload, token);
        if (dataRes.status === 'success') {
            yield put(getYachtinfo({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    }
    catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* deleteYachtinfo({ payload, token }) {
    const { items } = payload;
    try {
        const dataRes = yield yachtInformationDeleteYachtinfo(items, token);
        if (dataRes.status === 'success') yield put(getYachtinfo({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {

                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* updateStatusYachtinfo({ payload, token }) {
    try {
        const dataRes = yield yachtInformationUpdateChangeStatusYachtInfo(payload, token);
        if (dataRes.status === 'success') {
            yield put(getYachtinfo({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    }
    catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}


// Company Profile
export function* watchLoadYachtinfo() {
    yield takeEvery(
        YACHT_INFOMATION_LOAD_YACHTINFO,
        yachtInformationLoadYachtinfo
    );
}

export function* watchCreateYachtinfo() {
    yield takeEvery(
        YACHT_INFOMATION_CREATE_YACHTINFO,
        yachtInfoCreateYachtinfo
    );
}

export function* watchUpdateYachtinfo() {
    yield takeEvery(
        YACHT_INFOMATION_UPDATE_YACHTINFO,
        updateYachtinfo
    );
}

export function* watchDeleteYachtinfo() {
    yield takeEvery(
        YACHT_INFOMATION_DELETE_YACHTINFO,
        deleteYachtinfo
    );
}

export function* watchUpdateStatusYachtinfo() {
    yield takeEvery(
        YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS,
        updateStatusYachtinfo
    );
}

export default function* rootSaga() {
    yield all([
        //  Company Profile
        fork(watchLoadYachtinfo),
        fork(watchCreateYachtinfo),
        fork(watchUpdateYachtinfo),
        fork(watchDeleteYachtinfo),
        fork(watchUpdateStatusYachtinfo),
    ])
}