import { all, fork, put, takeEvery } from "redux-saga/effects";

import {

    PROMOTION_LOAD_PROMOTION_CATEGORY,
    // PROMOTION_LOAD_PROMOTION_CATEGORY_SUCCESS,
    PROMOTION_CREATE_PROMOTION_CATEGORY,
    // PROMOTION_CREATE_PROMOTION_CATEGORY_SUCCESS,
    PROMOTION_UPDATE_PROMOTION_CATEGORY,
    // PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS,
    PROMOTION_DELETE_PROMOTION_CATEGORY,
    // PROMOTION_DELETE_PROMOTION_CATEGORY_SUCCESS,

    PROMOTION_LOAD_CUSTOMER_TYPE,
    // PROMOTION_LOAD_CUSTOMER_TYPE_SUCCESS,
    PROMOTION_CREATE_CUSTOMER_TYPE,
    // PROMOTION_CREATE_CUSTOMER_TYPE_SUCCESS,
    PROMOTION_UPDATE_CUSTOMER_TYPE,
    // PROMOTION_UPDATE_CUSTOMER_TYPE_SUCCESS,
    PROMOTION_DELETE_CUSTOMER_TYPE,
    // PROMOTION_DELETE_CUSTOMER_TYPE_SUCCESS,

    PROMOTION_LOAD_PROMOTION_LIST,
    // PROMOTION_LOAD_PROMOTION_LIST_SUCCESS,
    PROMOTION_CREATE_PROMOTION_LIST,
    // PROMOTION_CREATE_PROMOTION_LIST_SUCCESS,
    PROMOTION_UPDATE_PROMOTION_LIST,
    // PROMOTION_UPDATE_PROMOTION_LIST_SUCCESS,
    PROMOTION_DELETE_PROMOTION_LIST,
    // PROMOTION_DELETE_PROMOTION_LIST_SUCCESS,


} from "../actions";

import {

    promotionGetPromotionCategory,
    promotionAddPromotionCategory,
    promotionUpdatePromotionCategory,
    promotionDeletePromotionCategory,

    promotionGetCustomerType,
    promotionAddCustomerType,
    promotionUpdateCustomerType,
    promotionDeleteCustomerType,

    promotionGetPromotion,
    promotionAddPromotion,
    promotionUpdatePromotion,
    promotionDeletePromotion,

} from "../../helpers/promotion-api";

import { NotificationManager } from '../../components/common/react-notifications';


import {

    getPromotionCategory,
    getPromotionCategorySuccess,
    getCustomerType,
    getCustomerTypeSuccess,
    getPromotionlist,
    getPromotionlistSuccess,

} from "./actions";

const PullNotificationManager = async (text) => {
    // eslint-disable-next-line no-return-await
    await NotificationManager.warning(text, 'Error', 3000, null, null, '');
}

//  PromotionCategory
function* promotionLoadPromotionCategory({ payload, token }) {
    try {
        const dataRes = yield promotionGetPromotionCategory(payload, token);
        if (dataRes.status === 'success') {
            yield put(getPromotionCategorySuccess(dataRes.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* promotionCreatePromotionCategory({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield promotionAddPromotionCategory(payload, token);
        if (dataRes.status === 'success') {
            yield put(getPromotionCategory({ yacht_id: payload.yacht_id }, token));
        } else
            if
                (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }

            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* updatePromotionCategory({ payload, token }) {
    try {
        const dataRes = yield promotionUpdatePromotionCategory(payload, token);
        if (dataRes.status === 'success') {
            yield put(getPromotionCategory({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    }
    catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* deletePromotionCategory({ payload, token }) {
    const { items } = payload;
    try {
        const dataRes = yield promotionDeletePromotionCategory(items, token);
        if (dataRes.status === 'success') yield put(getPromotionCategory({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {

                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}


//  CustomerType
function* promotionLoadCustomerType({ payload, token }) {
    try {
        const dataRes = yield promotionGetCustomerType(payload, token);
        if (dataRes.status === 'success') {
            yield put(getCustomerTypeSuccess(dataRes.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* promotionCreateCustomerType({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield promotionAddCustomerType(payload, token);
        if (dataRes.status === 'success') {
            yield put(getCustomerType({ yacht_id: payload.yacht_id }, token));
        } else
            if
                (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }

            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* updateCustomerType({ payload, token }) {
    try {
        const dataRes = yield promotionUpdateCustomerType(payload, token);
        if (dataRes.status === 'success') {
            yield put(getCustomerType({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    }
    catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* deleteCustomerType({ payload, token }) {
    const { items } = payload;
    try {
        const dataRes = yield promotionDeleteCustomerType(items, token);
        if (dataRes.status === 'success') yield put(getCustomerType({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {

                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

//  Promotionlist
function* promotionLoadPromotionlist({ payload, token }) {
    try {
        const dataRes = yield promotionGetPromotion(payload, token);
        if (dataRes.status === 'success') {
            yield put(getPromotionlistSuccess(dataRes.data));
        } else
            if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* promotionCreatePromotionlist({ payload, token }) {
    try {
        // const { yacht_id } = payload;
        const dataRes = yield promotionAddPromotion(payload, token);
        if (dataRes.status === 'success') {
            yield put(getPromotionlist({ yacht_id: payload.yacht_id }, token));
        } else
            if
                (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }

            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            }
            else {
                yield PullNotificationManager(`${dataRes.msg}`);
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* updatePromotionlist({ payload, token }) {
    try {
        const dataRes = yield promotionUpdatePromotion(payload, token);
        if (dataRes.status === 'success') {
            yield put(getPromotionlist({ yacht_id: payload.yacht_id }, token));
        } else
            if (dataRes.status === 'error' && dataRes.err.code === 'ER_DUP_ENTRY') {
                yield PullNotificationManager("Oops! The data name is duplicated. Please change the data name.")
                // yield put(settingErrorMessage("Oops! The data name is duplicated. Please change the data name."));
            }
            else if (dataRes.status === 'error' && dataRes.auth === false) {
                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }
    }
    catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}

function* deletePromotionlist({ payload, token }) {
    const { items } = payload;
    try {
        const dataRes = yield promotionDeletePromotion(items, token);
        if (dataRes.status === 'success') yield put(getPromotionlist({ yacht_id: payload.yacht_id }, token));
        else
            if (dataRes.status === 'error' && dataRes.auth === false) {

                yield PullNotificationManager("TIME OUT! Please Login again.")
                // yield put(settingErrorMessage("TIME OUT! Please Login again."))
            } else {
                yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
                // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
            }

    } catch (error) {
        yield PullNotificationManager("OMG! Please wait about 30s and refresh your browser again.")
        // yield put(settingErrorMessage("OMG! Please wait about 30s and refresh your browser again."));
    }
}


// PromotionCategory
export function* watchLoadPromotionCategory() {
    yield takeEvery(
        PROMOTION_LOAD_PROMOTION_CATEGORY,
        promotionLoadPromotionCategory
    );
}

export function* watchCreatePromotionCategory() {
    yield takeEvery(
        PROMOTION_CREATE_PROMOTION_CATEGORY,
        promotionCreatePromotionCategory
    );
}

export function* watchUpdatePromotionCategory() {
    yield takeEvery(
        PROMOTION_UPDATE_PROMOTION_CATEGORY,
        updatePromotionCategory
    );
}

export function* watchDeletePromotionCategory() {
    yield takeEvery(
        PROMOTION_DELETE_PROMOTION_CATEGORY,
        deletePromotionCategory
    );
}

//  CustomerType

export function* watchLoadCustomerType() {
    yield takeEvery(
        PROMOTION_LOAD_CUSTOMER_TYPE,
        promotionLoadCustomerType
    );
}

export function* watchCreateCustomerType() {
    yield takeEvery(
        PROMOTION_CREATE_CUSTOMER_TYPE,
        promotionCreateCustomerType
    );
}

export function* watchUpdateCustomerType() {
    yield takeEvery(
        PROMOTION_UPDATE_CUSTOMER_TYPE,
        updateCustomerType
    );
}

export function* watchDeleteCustomerType() {
    yield takeEvery(
        PROMOTION_DELETE_CUSTOMER_TYPE,
        deleteCustomerType
    );
}

// Promotionlist

export function* watchLoadPromotionlist() {
    yield takeEvery(
        PROMOTION_LOAD_PROMOTION_LIST,
        promotionLoadPromotionlist
    );
}

export function* watchCreatePromotionlist() {
    yield takeEvery(
        PROMOTION_CREATE_PROMOTION_LIST,
        promotionCreatePromotionlist
    );
}

export function* watchUpdatePromotionlist() {
    yield takeEvery(
        PROMOTION_UPDATE_PROMOTION_LIST,
        updatePromotionlist
    );
}

export function* watchDeletePromotionlist() {
    yield takeEvery(
        PROMOTION_DELETE_PROMOTION_LIST,
        deletePromotionlist
    );
}

export default function* rootSaga() {
    yield all([
        //  PromotionCategory
        fork(watchLoadPromotionCategory),
        fork(watchCreatePromotionCategory),
        fork(watchUpdatePromotionCategory),
        fork(watchDeletePromotionCategory),
        //  CustomerType
        fork(watchLoadCustomerType),
        fork(watchCreateCustomerType),
        fork(watchUpdateCustomerType),
        fork(watchDeleteCustomerType),
        // Promotionlist
        fork(watchLoadPromotionlist),
        fork(watchCreatePromotionlist),
        fork(watchUpdatePromotionlist),
        fork(watchDeletePromotionlist),
    ])
}