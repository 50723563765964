// import { defaultLocale, localeOptions } from '../../constants/defaultValues'

import {

	LOGOUT_USER,
	YACHT_INFOMATION_LOAD_YACHTINFO,
	YACHT_INFOMATION_LOAD_YACHTINFO_SUCCESS,
	YACHT_INFOMATION_CREATE_YACHTINFO,
	YACHT_INFOMATION_CREATE_YACHTINFO_SUCCESS,
	YACHT_INFOMATION_UPDATE_YACHTINFO,
	YACHT_INFOMATION_UPDATE_YACHTINFO_SUCCESS,
	YACHT_INFOMATION_DELETE_YACHTINFO,
	YACHT_INFOMATION_DELETE_YACHTINFO_SUCCESS,
	YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS,
	YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS_SUCCESS,

} from '../actions';

const INIT_STATE = {

	YachtInfoLoading: false,
	dataYachtInfo: null,
	// dataCompanyInformation: null,

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case YACHT_INFOMATION_LOAD_YACHTINFO:
			return { ...state, YachtInfoLoading: true, }
		case YACHT_INFOMATION_LOAD_YACHTINFO_SUCCESS:
			return { ...state, YachtInfoLoading: false, dataYachtInfo: action.payload }
		case YACHT_INFOMATION_CREATE_YACHTINFO:
			return { ...state, YachtInfoLoading: true }
		case YACHT_INFOMATION_CREATE_YACHTINFO_SUCCESS:
			return { ...state, YachtInfoLoading: false }
		case YACHT_INFOMATION_UPDATE_YACHTINFO:
			return { ...state, YachtInfoLoading: true }
		case YACHT_INFOMATION_UPDATE_YACHTINFO_SUCCESS:
			return { ...state, YachtInfoLoading: false }
		case YACHT_INFOMATION_DELETE_YACHTINFO:
			return { ...state, YachtInfoLoading: true }
		case YACHT_INFOMATION_DELETE_YACHTINFO_SUCCESS:
			return { ...state, YachtInfoLoading: false }
		case YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS:
			return { ...state, YachtInfoLoading: true }
		case YACHT_INFOMATION_UPDATE_YACHTINFO_STATUS_SUCCESS:
			return { ...state, YachtInfoLoading: false }
		case LOGOUT_USER:
			return {
				...state,
				YachtInfoLoading: false,
				dataYachtInfo: null,
			};

		default: return { ...state };
	}
}