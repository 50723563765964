// eslint-disable-next-line import/no-cycle
import {
    SETTING_ERROR_MESSAGE,
    CLOSE_SETTING_ERROR_MESSAGE,

    // UPDATE_HOTEL,
    // UPDATE_HOTEL_SUCCESS,
    // ROLECATEGORY ชื่อ
    USER_MANAGEMENT_LOAD_NATIONALITY,
    USER_MANAGEMENT_LOAD_NATIONALITY_SUCCESS,
    // USER_MANAGEMENT_LOAD_ALL_DATA,
    // USER_MANAGEMENT_LOAD_ALL_DATA_SUCCESS,
    USER_MANAGEMENT_ADD_NAME_ROLECATEGORY,
    USER_MANAGEMENT_ADD_NAME_ROLECATEGORY_SUCCESS,
    USER_MANAGEMENT_LOAD_ROLECATEGORY,
    USER_MANAGEMENT_LOAD_ROLECATEGORY_SUCCESS,
    USER_MANAGEMENT_DELETE_ROLECATEGORY,
    USER_MANAGEMENT_DELETE_ROLECATEGORY_SUCCESS,
    USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY,
    USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY_SUCCESS,

    // USER_MANAGEMENT_ADD_NAME_POSITION,
    // USER_MANAGEMENT_ADD_NAME_POSITION_SUCCESS,
    // USER_MANAGEMENT_UPDATE_NAME_POSITION,
    // USER_MANAGEMENT_UPDATE_NAME_POSITION_SUCCESS,
    // USER_MANAGEMENT_LOAD_POSITION,
    // USER_MANAGEMENT_LOAD_POSITION_SUCCESS,
    // USER_MANAGEMENT_DELETE_POSITION,
    // USER_MANAGEMENT_DELETE_POSITION_SUCCESS,

    CREATE_USER,
    CREATE_USER_SUCCESS,
    LOAD_USER,
    LOAD_USER_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    USER_MANAGEMENT_UPDATE_USER,
    USER_MANAGEMENT_UPDATE_USER_SUCCESS,
    USER_MANAGEMENT_CREATE_USER_PASS,
    USER_MANAGEMENT_CREATE_USER_PASS_SUCCESS,
    USER_MANAGEMENT_RESET_USER_PASS,
    USER_MANAGEMENT_RESET_USER_PASS_SUCCESS,

    ROLE_MANAGEMENT_CREATE_ROLE,
    ROLE_MANAGEMENT_CREATE_ROLE_SUCCESS,
    ROLE_MANAGEMENT_LOAD_ROLE,
    ROLE_MANAGEMENT_LOAD_ROLE_SUCCESS,
    ROLE_MANAGEMENT_DELETE_ROLE,
    // ROLE_MANAGEMENT_DELETE_ROLE_SUCCESS,
    ROLE_MANAGEMENT_UPDATE_ROLE,

    // ROLE_MANAGEMENT_CREATE_PERMISSION,
    // ROLE_MANAGEMENT_LOAD_PERMISSION,
    // ROLE_MANAGEMENT_LOAD_PERMISSION_SUCCESS,
    // ROLE_MANAGEMENT_UPDATE_PERMISSION,
    // // ROLE_MANAGEMENT_UPDATE_PERMISSION_SUCCESS,
    // ROLE_MANAGEMENT_DELETE_PERMISSION,

    ROLE_MANAGEMENT_CREATE_ROLELIST,
    // ROLE_MANAGEMENT_CREATE_ROLELIST_SUCCESS,
    ROLE_MANAGEMENT_LOAD_ROLELIST,
    ROLE_MANAGEMENT_LOAD_ROLELIST_SUCCESS,
    ROLE_MANAGEMENT_DELETE_ROLELIST,
    // ROLE_MANAGEMENT_DELETE_ROLELIST_SUCCESS,
    ROLE_MANAGEMENT_UPDATE_ROLELIST,
    // ROLE_MANAGEMENT_UPDATE_ROLELIST_SUCCESS,
    ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT,
    ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT_SUCCESS,
    ROLE_MANAGEMENT_DELETE_ROLELIST_USER

} from '../actions';


// // export const changeLocale = (locale) => {
// //     localStorage.setItem('currentLanguage', locale);
// //     return (
// //         {
// //             type: CHANGE_LOCALE,
// //             payload: locale
// //         }
// //     )
// // }
export const settingErrorMessage = (data) => ({
    type: SETTING_ERROR_MESSAGE,
    payload: data
})

export const closeSettingErrorMessage = () => ({
    type: CLOSE_SETTING_ERROR_MESSAGE
});

// // -------------------- Country --------------------- //
export const getNationality = (token) => ({
    type: USER_MANAGEMENT_LOAD_NATIONALITY,
    token
});
export const getNationalitySuccess = (data) => ({
    type: USER_MANAGEMENT_LOAD_NATIONALITY_SUCCESS,
    payload: data
});
// // ------------------ Department ------------------- //
// export const userManagementLoadAll = data => ({
//     type: USER_MANAGEMENT_LOAD_ALL_DATA,
//     payload: data
//   })
//   export const userManagementLoadAllSuccess = (dataPermis, dataRoleCategory, listRoleCategory, data, listPos, dataUser, listEMP, dataCo) => ({
//     type: USER_MANAGEMENT_LOAD_ALL_DATA_SUCCESS,
//     payload: { dataPermis: dataPermis, dataDepart: dataDepart, listDepart: listDepart, dataPos: dataPos, listPos: listPos, dataUser: dataUser, listEMP: listEMP, dataCo: dataCo}
//   })
export const addRoleCategory = (data, token) => ({
    type: USER_MANAGEMENT_ADD_NAME_ROLECATEGORY,
    payload: data,
    token
});
export const addRoleCategorySuccess = () => ({
    type: USER_MANAGEMENT_ADD_NAME_ROLECATEGORY_SUCCESS
});
export const getRoleCategory = (data, token) => ({
    type: USER_MANAGEMENT_LOAD_ROLECATEGORY,
    payload: data,
    token
});
export const getRoleCategorySuccess = (data, list) => ({
    type: USER_MANAGEMENT_LOAD_ROLECATEGORY_SUCCESS,
    payload: data,
    payloadlist: list
});
export const deleteRoleCategory = (data, token) => ({
    type: USER_MANAGEMENT_DELETE_ROLECATEGORY,
    payload: data,
    token
});
export const deleteRoleCategorySuccess = () => ({
    type: USER_MANAGEMENT_DELETE_ROLECATEGORY_SUCCESS
});
export const updateRoleCategory = (data, token) => ({
    type: USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY,
    payload: data,
    token
});
export const updateRoleCategorySuccess = () => ({
    type: USER_MANAGEMENT_UPDATE_NAME_ROLECATEGORY_SUCCESS
});
// // -------------- possition ------------------ //
// export const addPosition = (data, token) => ({
//     type: USER_MANAGEMENT_ADD_NAME_POSITION,
//     payload: data,
//     token
// });
// export const addPositiontSuccess = () => ({
//     type: USER_MANAGEMENT_ADD_NAME_POSITION_SUCCESS
// });
// export const getPosition = (data, token) => ({
//     type: USER_MANAGEMENT_LOAD_POSITION,
//     payload: data,
//     token: token
// });
// export const getPositionSuccess = (data, list) => ({
//     type: USER_MANAGEMENT_LOAD_POSITION_SUCCESS,
//     payload: data,
//     payloadlist: list
// });
// export const updatePosition = (data, token) => ({
//     type: USER_MANAGEMENT_UPDATE_NAME_POSITION,
//     payload: data,
//     token
// });
// export const updatePositionSuccess = () => ({
//     type: USER_MANAGEMENT_UPDATE_NAME_POSITION_SUCCESS
// });
// export const deletePosition = (data, token) => ({
//     type: USER_MANAGEMENT_DELETE_POSITION,
//     payload: data,
//     token
// });
// export const deletePositionSuccess = () => ({
//     type: USER_MANAGEMENT_DELETE_POSITION_SUCCESS
// });

// // ------------ user ------------ //
export const creaetUser = (data, token) => ({
    type: CREATE_USER,
    payload: data,
    token
});
export const creaetUserSuccess = () => ({
    type: CREATE_USER_SUCCESS
});
export const getUser = (data, token) => ({
    type: LOAD_USER,
    payload: data,
    token
});
export const getUserSuccess = (data, list) => ({
    type: LOAD_USER_SUCCESS,
    payload: data,
    payloadlist: list
});
export const deleteUser = (data, token) => ({
    type: DELETE_USER,
    payload: data,
    token
});
export const deleteUserSuccess = () => ({
    type: DELETE_USER_SUCCESS
});
export const updateUser = (data, token) => ({
    type: USER_MANAGEMENT_UPDATE_USER,
    payload: data,
    token
});
export const updateUserSuccess = () => ({
    type: USER_MANAGEMENT_UPDATE_USER_SUCCESS
});
export const createUserPassword = (data, token) => ({
    type: USER_MANAGEMENT_CREATE_USER_PASS,
    payload: data,
    token
});
export const createUserPasswordSuccess = () => ({
    type: USER_MANAGEMENT_CREATE_USER_PASS_SUCCESS
});
export const resetPassword = (data, token) => ({
    type: USER_MANAGEMENT_RESET_USER_PASS,
    payload: data,
    token
});
export const resetPasswordSuccess = (data) => ({
    type: USER_MANAGEMENT_RESET_USER_PASS_SUCCESS,
    payload: data,

});
// // ---------------- Role management -------------------- //
export const addRole = (data, token) => ({
    type: ROLE_MANAGEMENT_CREATE_ROLE,
    payload: data,
    token
});
export const addRoleSuccess = () => ({
    type: ROLE_MANAGEMENT_CREATE_ROLE_SUCCESS
});
export const getRole = (data, token) => ({
    type: ROLE_MANAGEMENT_LOAD_ROLE,
    payload: data,
    token
});
export const getRoleSuccess = (data, list) => ({
    type: ROLE_MANAGEMENT_LOAD_ROLE_SUCCESS,
    payload: data,
    payloadlist: list
});
export const deleteRole = (data, token) => ({
    type: ROLE_MANAGEMENT_DELETE_ROLE,
    payload: data,
    token
});
export const updateRole = (data, token) => ({
    type: ROLE_MANAGEMENT_UPDATE_ROLE,
    payload: data,
    token
});
export const addRolelist = (data, token) => ({
    type: ROLE_MANAGEMENT_CREATE_ROLELIST,
    payload: data,
    token
});
export const getRolelist = (data, token) => ({
    type: ROLE_MANAGEMENT_LOAD_ROLELIST,
    payload: data,
    token
});
export const getRolelistSuccess = (data) => ({
    type: ROLE_MANAGEMENT_LOAD_ROLELIST_SUCCESS,
    payload: data
});
export const updateRolelist = (data, token) => ({
    type: ROLE_MANAGEMENT_UPDATE_ROLELIST,
    payload: data, 
    token
});
export const deleteRolelist = (data, token) => ({
    type: ROLE_MANAGEMENT_DELETE_ROLELIST,
    payload: data,
    token
});
export const deleteRolelistUser = (data, token) => ({
    type: ROLE_MANAGEMENT_DELETE_ROLELIST_USER,
    payload: data,
    token
});
export const getRoleObjectlist = (data) => ({
    type: ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT,
    payload: data,
});
export const getRolelistObjectSuccess = (data) => ({
    type: ROLE_MANAGEMENT_LOAD_ROLELIST_OBJECT_SUCCESS,
    payload: data
});
