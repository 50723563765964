// import { defaultLocale, localeOptions } from '../../constants/defaultValues'

import {

	LOGOUT_USER,
	PROMOTION_LOAD_PROMOTION_CATEGORY,
	PROMOTION_LOAD_PROMOTION_CATEGORY_SUCCESS,
	PROMOTION_CREATE_PROMOTION_CATEGORY,
	PROMOTION_CREATE_PROMOTION_CATEGORY_SUCCESS,
	PROMOTION_UPDATE_PROMOTION_CATEGORY,
	PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS,
	PROMOTION_DELETE_PROMOTION_CATEGORY,
	PROMOTION_DELETE_PROMOTION_CATEGORY_SUCCESS,

	PROMOTION_LOAD_CUSTOMER_TYPE,
	PROMOTION_LOAD_CUSTOMER_TYPE_SUCCESS,
	PROMOTION_CREATE_CUSTOMER_TYPE,
	PROMOTION_CREATE_CUSTOMER_TYPE_SUCCESS,
	PROMOTION_UPDATE_CUSTOMER_TYPE,
	PROMOTION_UPDATE_CUSTOMER_TYPE_SUCCESS,
	PROMOTION_DELETE_CUSTOMER_TYPE,
	PROMOTION_DELETE_CUSTOMER_TYPE_SUCCESS,

	PROMOTION_LOAD_PROMOTION_LIST,
	PROMOTION_LOAD_PROMOTION_LIST_SUCCESS,
	PROMOTION_CREATE_PROMOTION_LIST,
	PROMOTION_CREATE_PROMOTION_LIST_SUCCESS,
	PROMOTION_UPDATE_PROMOTION_LIST,
	PROMOTION_UPDATE_PROMOTION_LIST_SUCCESS,
	PROMOTION_DELETE_PROMOTION_LIST,
	PROMOTION_DELETE_PROMOTION_LIST_SUCCESS,

} from '../actions';

const INIT_STATE = {

	promotionLoading: false,
	promotionCategory: [],
	customerType: [],
	promotionlist: [],

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case PROMOTION_LOAD_PROMOTION_CATEGORY:
			return { ...state, promotionLoading: true, }
		case PROMOTION_LOAD_PROMOTION_CATEGORY_SUCCESS:
			return { ...state, promotionLoading: false, promotionCategory: action.payload }
		case PROMOTION_CREATE_PROMOTION_CATEGORY:
			return { ...state, promotionLoading: true }
		case PROMOTION_CREATE_PROMOTION_CATEGORY_SUCCESS:
			return { ...state, promotionLoading: false }
		case PROMOTION_UPDATE_PROMOTION_CATEGORY:
			return { ...state, promotionLoading: true }
		case PROMOTION_UPDATE_PROMOTION_CATEGORY_SUCCESS:
			return { ...state, promotionLoading: false }
		case PROMOTION_DELETE_PROMOTION_CATEGORY:
			return { ...state, promotionLoading: true }
		case PROMOTION_DELETE_PROMOTION_CATEGORY_SUCCESS:
			return { ...state, promotionLoading: false }


		case PROMOTION_LOAD_CUSTOMER_TYPE:
			return { ...state, promotionLoading: true, }
		case PROMOTION_LOAD_CUSTOMER_TYPE_SUCCESS:
			return { ...state, promotionLoading: false, customerType: action.payload }
		case PROMOTION_CREATE_CUSTOMER_TYPE:
			return { ...state, promotionLoading: true }
		case PROMOTION_CREATE_CUSTOMER_TYPE_SUCCESS:
			return { ...state, promotionLoading: false }
		case PROMOTION_UPDATE_CUSTOMER_TYPE:
			return { ...state, promotionLoading: true }
		case PROMOTION_UPDATE_CUSTOMER_TYPE_SUCCESS:
			return { ...state, promotionLoading: false }
		case PROMOTION_DELETE_CUSTOMER_TYPE:
			return { ...state, promotionLoading: true }
		case PROMOTION_DELETE_CUSTOMER_TYPE_SUCCESS:
			return { ...state, promotionLoading: false }

		case PROMOTION_LOAD_PROMOTION_LIST:
			return { ...state, promotionLoading: true, }
		case PROMOTION_LOAD_PROMOTION_LIST_SUCCESS:
			return { ...state, promotionLoading: false, promotionlist: action.payload }
		case PROMOTION_CREATE_PROMOTION_LIST:
			return { ...state, promotionLoading: true }
		case PROMOTION_CREATE_PROMOTION_LIST_SUCCESS:
			return { ...state, promotionLoading: false }
		case PROMOTION_UPDATE_PROMOTION_LIST:
			return { ...state, promotionLoading: true }
		case PROMOTION_UPDATE_PROMOTION_LIST_SUCCESS:
			return { ...state, promotionLoading: false }
		case PROMOTION_DELETE_PROMOTION_LIST:
			return { ...state, promotionLoading: true }
		case PROMOTION_DELETE_PROMOTION_LIST_SUCCESS:
			return { ...state, promotionLoading: false }
		case LOGOUT_USER:
			return {
				...state,
				promotionLoading: false,
				promotionCategory: [],
				customerType: [],
				promotionlist: [],
			};

		default: return { ...state };
	}
}